import React from 'react';

/**
 * This class is a base class for React Components that display API data.
 *
 * To extend this class you need to override the following methods:
 *
 * - updateData()
 * - initialData()
 *
 * You can send data to the API by calling:
 *
 * - writeData(data)
 *
 * When a ApiDataComponent component is created it must be provided with the following properties:
 *
 * - dataProvider: An ApiData object, which is used to fetch data for this component.
 *   registerComponent() and unregisterComponent() methods are called on this object.
 * - initialDataUrl: URL to fetch initial data for building the components content.
 *   This data is provided to the initialData() method when it is received from the API.
 * - updateDataUrl: URL to fetch regular updates for components status.
 *   This data is provided to the updateData() method when it is received from the API.
 * - writeDataUrl: URL to write data to when calling writeData() (HTTP POST request).
 *
 * You may not provide initialDataUrl, updateDataUrl or writeDataUrl in case one of them is not needed.
 */
export default class ApiDataComponent extends React.Component {

    /**
     * @returns {string} url for fetching initial data for this component
     */
    getInitialDataUrl() {
        if (this.state.initialDataUrl) {
            // console.log('using this.state.initialDataUrl for initial api data ' + this.state.initialDataUrl);
            return this.state.initialDataUrl;
        }
        if (this.props.initialDataUrl) {
            //console.log('using this.props.initialDataUrl for initial api data ' + this.props.initialDataUrl);
            return this.props.initialDataUrl;
        }
        return null;
    }

    /**
     * @returns {string} url for fetching update data for this component
     */
    getUpdateDataUrl() {
        if (this.props.updateDataUrl) {
            return this.props.updateDataUrl;
        }
        return null;
    }

    /**
     * @returns {string} url for fetching update data for this component
     */
    getWriteDataUrl() {
        if (this.props.writeDataUrl) {
            return this.props.writeDataUrl;
        }
        return null;
    }

    hasInitialData = false;
    pendingUpdateData = null;

    /**
     * populate initial data, making sure initial data is populated before update data.
     * @param data
     */
    populateInitialData(data) {
        this.initialData(data);
        this.hasInitialData = true;
        if (this.pendingUpdateData) {
            this.updateData(this.pendingUpdateData);
            this.pendingUpdateData = null;
        }
    }

    /**
     * populate update data, making sure initial data is populated before update data.
     * @param data
     */
    populateUpdateData(data) {
        if (this.getInitialDataUrl() && !this.hasInitialData) {
            this.pendingUpdateData = data;
            return;
        }
        this.updateData(data);
    }

    /**
     * This method should be implemented in subclasses.
     * It receives data after a successful fetch from the initial data URL.
     * @param data
     * @see getInitialDataUrl()
     */
    initialData(data) {}

    /**
     * This method should be implemented in subclasses.
     * It receives data after a successful fetch from the update data URL.
     * @param data
     * @see getUpdateDataUrl()
     */
    updateData(data) {}

    /**
     * This method should be called in subclasses.
     * It receives data for writing to the API.
     * @param data
     * @param successCallback called after successful request signature: function(responseData)
     * @param url
     * @see getWriteDataUrl()
     */
    writeData(data, successCallback = null, url = null) {
        this.props.dataProvider.writeData(this, data, successCallback, url);
    }

    uploadFile(file, successCallback = null) {
        this.props.dataProvider.uploadFile(this, file, successCallback);
    }

    componentDidMount() {
        if (this.props.dataProvider) {
            this.props.dataProvider.registerComponent(this);
        }
    }

    componentWillUnmount() {
        if (this.props.dataProvider) {
            this.props.dataProvider.unregisterComponent(this);
        }
    }

    reloadInitialData() {
        if (this.props.dataProvider) {
            this.props.dataProvider.unregisterComponent(this);
            this.props.dataProvider.registerComponent(this);
        }
    }

}