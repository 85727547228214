import React, {useEffect} from 'react';

import Container from '@material-ui/core/Container';
import Button from "@mui/material/Button";
import {useHistory, useLocation} from "react-router-dom";
import {ReactSession} from 'react-client-session';
import useFetch from "./UseFetch";
import {useParams} from 'react-router-dom'
import {toast} from "react-toastify";
import ReactGA from "react-ga4";
import {confirmAlert} from "react-confirm-alert";

export default function KontoLoeschen(props) {

    const {token} = useParams()

    const location = useLocation();

    useEffect(() => {
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);

    useEffect(() => {
        if (ReactSession.get('token') === undefined && token === undefined) {
            history.push('/login');
        }
        document.title = 'Konto löschen';
    }, []);

    const api = useFetch();
    const [onUpdate, setOnUpdate] = React.useState(false);

    const history = useHistory();

    const notify = (message, error = false) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const confirmDeleteAccount = () => {
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du Dein Benutzerkonto wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let url = process.env.REACT_APP_API_URL + '/user/delete-account';
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 200) {
                            ReactSession.set('token', undefined);
                            ReactSession.set('refreshToken', undefined);
                            notify('Dein Konto wurde erfolgreich gelöscht')
                            props.sessionGotUpdate(true);
                            history.push('/');
                        } else if (response && response.status != 200) {
                            notify('API Fehler: ' + response.status, true)
                        }
                    }
                },
                {
                    label: 'Nein',
                    onClick: async () => {
                        window.history.replaceState({}, document.title)
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    backgroundColor: '#FFFFFF',
                }}>
                <div
                    className='jumbotron'
                    style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        paddingBottom: 10,
                    }}>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: 20,
                        }}>
                        Konto löschen:
                    </p>
                </div>
                <div style={{textAlign: 'center', maxWidth: '70%', margin: 'auto', paddingLeft: '20px'}}>
                    <p style={{textAlign: 'left'}}>Folgende Dinge werden gelöscht</p>
                    <p style={{textAlign: 'left'}}>- Konto (E-Mail-Adresse)</p>
                    <p style={{textAlign: 'left'}}>- Wunschlisten</p>
                    <p style={{textAlign: 'left'}}>- Preisalarme</p>
                </div>
                <React.Fragment>
                    <Button
                        style={{
                            width: '70%',
                            backgroundColor: '#f87800',
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        disabled={onUpdate}
                        onClick={confirmDeleteAccount}>
                        <p style={{textAlign: 'center'}}>Lösche Dein Konto</p>
                    </Button>
                </React.Fragment>
            </div>
        </Container>
    );
}
