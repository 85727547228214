import React, {useEffect} from 'react';

import Container from '@material-ui/core/Container';
import {Image} from "@mui/icons-material";
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory, useLocation} from "react-router-dom";
import base64 from 'base-64'
import {ReactSession} from 'react-client-session';
import useFetch from "./UseFetch";
import {useParams} from 'react-router-dom'
import {toast} from "react-toastify";
import ReactGA from "react-ga4";

export default function PasswortReset(props) {

    const {token} = useParams()

    const location = useLocation();

    useEffect(() => {
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);

    useEffect(() => {
        if (ReactSession.get('token') === undefined && token === undefined) {
            history.push('/login');
        }
        if (token) {
            ReactSession.set('newPassword', true)
            ReactSession.set('token', token)
        }
        document.title = 'Passwort zurücksetzen';
    }, []);

    const api = useFetch();
    const [onUpdate, setOnUpdate] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);

    const history = useHistory();

    const updatePassword = async () => {
        console.log('updatePassword');
        if (validate()) {

            if (token) {
                let updatedAttributes = {
                    password,
                    token
                };

                let url = process.env.REACT_APP_API_URL + '/user/new-password';
                const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response && response.status != '401' && data) {
                    ReactSession.set('newPassword', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    history.push('/');
                    props.sessionGotUpdate();
                    notify('Dein Passwort wurde erfolgreich aktualisiert');
                } else if ((data.errors && data.errors[0]) && response.status == '401') {
                    notify('Dieser Token ist nicht mehr gültig', true);
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                }
            } else {

                let updatedAttributes = {
                    password
                };

                let url = process.env.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

                const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

                if (data.data) {
                    history.push('/');
                    props.sessionGotUpdate();
                    notify('Dein Passwort wurde erfolgreich aktualisiert');
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                }
            }

        }

    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        console.log(e.charCode);
        if (e.charCode === 13) {
            updatePassword();
        }
    };

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const validate = (send = false) => {
        var error = false;

        if (password === '' || (send && !password)) {
            setPasswordError('Password darf nicht leer sein!');
            error = true;
        } else if (password.length < 8) {
            setPasswordError('Password muss mindestens 8 Zeichen lang sein');
            error = true;
        } else {
            setPasswordError(undefined);
        }

        if (passwordVerify === '' || (send && !passwordVerify)) {
            setPasswordVerifyError('PasswordVerify darf nicht leer sein!');
            error = true;
        } else if (passwordVerify.length < 8) {
            setPasswordVerifyError('Password muss mindestens 8 Zeichen lang sein');
            error = true;
        } else {
            setPasswordVerifyError(undefined);
        }

        if (password.trim() !== passwordVerify.trim()) {
            error = true;
            setPasswordError('Passwörter stimmen nicht überein!');
            setPasswordVerifyError('Passwörter stimmen nicht überein!');
        }

        return !error;

    };

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    backgroundColor: '#FFFFFF',
                }}>
                <div
                    className='jumbotron'
                    style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        paddingBottom: 10,
                    }}>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: 20,
                        }}>
                        Passwort ändern:
                    </p>
                </div>
                <React.Fragment>
                    <TextField
                        label="Passwort"
                        type="password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setPasswordError(true);
                            } else {
                                setPasswordError(false);
                            }
                            setPassword(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={password}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={passwordError}
                        helperText={passwordError}
                    />
                    <TextField
                        label="Passwort wiederholen"
                        type="password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setPasswordVerifyError(true);
                            } else {
                                setPasswordVerifyError(false);
                            }
                            setPasswordVerify(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={passwordVerify}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={passwordVerifyError}
                        helperText={passwordVerifyError}
                    />
                    <Button
                        style={{
                            width: '70%',
                            backgroundColor: '#f87800',
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        disabled={onUpdate}
                        onClick={updatePassword}>
                        <p style={{textAlign: 'center'}}>Senden</p>
                    </Button>
                </React.Fragment>
            </div>
        </Container>
    );
}
