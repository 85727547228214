import * as React from "react";
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory, useLocation} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {toast} from "react-toastify";
import useFetch from "./UseFetch";
import ReactGA from "react-ga4";
import {useEffect} from "react";

export default function Registration() {

    const api = useFetch();
    const [mail, setMail] = React.useState('');
    const [mailError, setMailError] = React.useState(undefined);
    const [username, setUsername] = React.useState('');
    const [usernameError, setUsernameError] = React.useState(undefined);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(undefined);
    const [passwordValidate, setPasswordValidate] = React.useState('');
    const [passwordValidateError, setPasswordValidateError] = React.useState(undefined);
    const [onRegistration, setOnRegistration] = React.useState(false);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        document.title = 'Registrierung';
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);


    const register = async () => {
        console.log('register');

        if (
            validate()
        ) {
            setOnRegistration(true);

            let url = process.env.REACT_APP_API_URL + '/user/register';
            const {response, data} = await api(url, 'POST', JSON.stringify({
                data: {
                    attributes: {
                        email: mail.trim(),
                        username: username.trim(),
                        password: password.trim()
                    }
                }
            }));

            if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                console.log('wrong credentials');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                setMailError(data.errors[0].meta.email);
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                setPasswordError(data.errors[0].meta.password);
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                setUsernameError(data.errors[0].meta.username);
            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            } else if (data &&
                data.errors &&
                data.errors[0] &&
                data.errors[0].status == '400'
            ) {
                console.log('bad');
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            } else if (data.data && data.data.id) {
                console.log('right credentials');
                history.push('/login');
                notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
                //setRegistration(false);

            }
            setOnRegistration(false)
        } else {
            validate(true);
        }
    };

    const validate = (send = false) => {
        let error = false;
        if (username == '' || (send && !username)) {
            error = true;
            setUsernameError('Benutzername darf nicht leer sein!');
        } else {
            setUsernameError(undefined);
        }
        if (password == '' || (send && !password)) {
            error = true;
            setPasswordError('Passwort darf nicht leer sein!');
        } else {
            setPasswordError(undefined);
        }

        if (mail == '' || (send && !mail)) {
            error = true;
            setMailError('E-Mail darf nicht leer sein!');
        } else if (mail && !validateEmail(mail.trim())) {
            error = true;
            setMailError('Keine gültige E-Mail!');
        } else {
            setMailError(undefined);
        }
        if (passwordValidate == '' || (send && !passwordValidate)) {
            error = true;
            setPasswordValidateError('Password darf nicht leer sein!');
        } else {
            setPasswordValidateError(undefined);
        }
        if (password.trim() !== passwordValidate.trim()) {
            error = true;
            setPasswordError('Passwörter stimmen nicht überein!');
            setPasswordValidateError('Passwörter stimmen nicht überein!');
        }
        return !error;
    };

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            register();
        }
    };

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <div
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    backgroundColor: '#FFFFFF',
                    marginTop: -20
                }}>
                <div
                    className='jumbotron'
                    style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        paddingBottom: 10,
                        marginBottom: 25,
                    }}>
                    <img
                        src={require('./../images/icon.png')}
                        height={120}
                        style={{backgroundColor: 'white'}}
                    />
                    {/*<h1 style={{backgroundColor: 'white'}}>*/}
                    {/*    nicer-<span style={{color: '#f87800'}}>pricer</span>*/}
                    {/*</h1>*/}
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: 20,
                        }}>
                        Registrierung:
                    </p>
                </div>
                <React.Fragment>
                    <TextField
                        label="E-Mail"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setMailError(true);
                            } else {
                                setMailError(false);
                            }
                            setMail(e.target.value.trim());
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={mail}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={mailError ? true : false}
                        helperText={mailError}
                    />
                    <TextField
                        label="Benutzername"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setUsernameError(true);
                            } else {
                                setUsernameError(false);
                            }
                            setUsername(e.target.value.trim());
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={username}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={usernameError ? true : false}
                        helperText={usernameError}
                    />
                    <TextField
                        label="Passwort"
                        type="password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setPasswordError(true);
                            } else {
                                setPasswordError(false);
                            }
                            setPassword(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={password}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                    />
                    <TextField
                        label="Passwort (wiederholen)"
                        type="password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setPasswordValidateError(true);
                            } else {
                                setPasswordValidateError(false);
                            }
                            setPasswordValidate(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={passwordValidate}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={passwordValidateError ? true : false}
                        helperText={passwordValidateError}
                    />
                    <Button
                        style={{
                            width: '70%',
                            backgroundColor: '#f87800',
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        disabled={onRegistration}
                        onClick={register}>
                        <p style={{textAlign: 'center'}}>Registrieren</p>
                    </Button>
                    <Button
                        style={{width: '70%', margin: 1, marginBottom: 20}}
                        onClick={e => {
                            setMail(undefined);
                            setUsername(undefined);
                            setPassword(undefined);
                            setPasswordValidate(undefined);
                            history.push('/login');
                        }}>
                        <p style={{textAlign: 'center'}}>Login</p>
                    </Button>
                </React.Fragment>
            </div>
        </Container>
    );
}
