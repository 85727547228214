import * as React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {useEffect, useRef, useState} from "react";
import {Clear, Delete, Edit, Info, NotificationAdd, SaveAlt, ShoppingCart} from "@mui/icons-material";
import {Grid, Hidden, TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import base64 from "base-64";
import {ReactSession} from "react-client-session";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {MoonLoader} from "react-spinners"; // Import css
import {css} from "@emotion/react";
import * as d3 from 'd3';
import './WunschlistenItem.css';
import LineChart from "./LineChart";
import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useFetch from "./UseFetch";
import ReactTooltip from "react-tooltip";
import moment from 'moment'

function parseDate(input) {
    var parts = input.match(/(\d+)/g);
    return new Date(parts[2], parts[1] - 1, parts[0]);
}


function Chart(props) {
    const {priceId} = props;
    const [data, setData] = useState([]);

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    useEffect(() => {
        regenerateData();
    }, []);

    function regenerateData() {

        let credentials = ReactSession.get('credentials');

        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(credentials));
        headers.append('Content-Type', 'application/vnd.api+json');

        let minPrice = null;
        let maxPrice = null;
        let minDate = null;
        let maxDate = null;
        fetch(process.env.REACT_APP_API_URL + '/priceHistory/' + priceId + '/data', {
            method: "GET",
            headers
        }).then(response => response.json())
            .then(data => {
                let tempData = [];
                data.data.map((item, index) => {
                    if (minDate === null || new Date(item.attributes.createdAt) < minDate) {
                        minDate = new Date(item.attributes.createdAt);
                    }
                    if (maxDate === null || new Date(item.attributes.createdAt) > maxDate) {
                        maxDate = new Date(item.attributes.createdAt);
                    }
                    if (minPrice === null || item.attributes.value < minPrice) {
                        minPrice = item.attributes.value;
                    }
                    if (maxPrice === null || item.attributes.value > maxPrice) {
                        maxPrice = item.attributes.value;
                    }
                    tempData.push({
                        date: new Date(item.attributes.createdAt),
                        value: item.attributes.value / 100,
                        tooltipContent: `<div style="text-align: left"><b>Preis: </b>&nbsp;&nbsp;${item.attributes.value / 100} €<br><b>Datum: </b>${(new Date(item.attributes.createdAt)).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        })}</div>`,
                    });
                })
                setMinPrice((minPrice / 100).toFixed(2));
                setMaxPrice((maxPrice / 100).toFixed(2));
                setMinDate(minDate.toLocaleString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }));
                setMaxDate(maxDate.toLocaleString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }));
                setData(tempData);


            })
            .catch(function (error) {
                console.log(error);
            });

    }

    return (
        <React.Fragment>

            {data && data.length > 0 ? <LineChart
                svgProps={{
                    margin: {top: 20, bottom: 10, left: 40, right: 40},
                    width: 600,
                    height: 150,
                }}
                // axisProps={{
                //     xLabel: 'X Axis',
                //     yLabel: 'Y Axis',
                // }}
                data={data}
                strokeWidth={3}
            /> : <p style={{textAlign: 'center'}}>loading</p>}

            {minPrice && maxPrice && minDate && maxDate ?
                <Grid container spacing={2} style={{marginBottom: 10}}>
                    <Grid item xs={6} style={{paddingLeft: 60}}>
                         <span style={{display: "inline-flex", alignItems: "center"}}>
                             <ArrowDownwardIcon sx={{fontSize: 26, color: 'green'}}/>&nbsp;
                             <b>{minPrice} €</b>&nbsp;&nbsp;
                             <ArrowUpwardIcon sx={{fontSize: 26, color: 'red'}}/>&nbsp;<b>{maxPrice} €</b>
                        </span>
                    </Grid>
                    <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', flexDirection: "row"}}>
                         <span style={{display: "inline-flex", alignItems: "center", paddingRight: 40}}>
                             <DateRangeIcon sx={{fontSize: 26}}/>&nbsp;&nbsp;
                             <b>{minDate} - {maxDate}</b>
                        </span>
                    </Grid>
                </Grid>
                : undefined}
        </React.Fragment>
    )
}


export default function WunschlistenItemDatagrid(props) {

    const {rows, newData, hasMore, reload, pauseInterval, continueInterval} = props;
    const api = useFetch();

    const [hasMoreT, setHasMoreT] = useState(true);

    const [items, setItems] = useState(null);
    const [price, setPrice] = useState(0);
    const [priceID, setPriceID] = useState(null);

    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [priceId, setPriceId] = useState();

    const handleClose = () => {
        setOpen(false);
        setPriceId(undefined);
    }

    const handleShow = () => setOpen(true);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const override = css`
    min-height: 20px;
  display: inline-flex;
`;

    const override2 = css`
    min-height: 20px;
    margin-right: 25px;
  display: inline-flex;
`;

    const confirmDelete = (id) => {

        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du dieses Produkt wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let {response, data} = await api(process.env.REACT_APP_API_URL + '/wishlistItem' + '/' + id, 'DELETE')


                        if (!response || response.status == 401) {
                            return;
                        }

                        if (response) {
                            reload(true, 'Dein Produkt wurde erfolgreich gelöscht')
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"

        });
    }
    const confirmDeleteAlert = (id) => {

        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diesen Alarm wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let {response, data} = await api(process.env.REACT_APP_API_URL + '/alert/' + id, 'DELETE')


                        if (!response || response.status == 401) {
                            return;
                        }

                        if (response) {
                            reload(true, 'Dein Alarm wurde erfolgreich gelöscht')
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }


    useEffect(() => {
        setHasMoreT(hasMore);
        if (rows !== null && rows.length !== 0) {

            rows.map((item, index) => {
                item.edit = false;
            })
            setItems(rows);
        }
    }, [])

    useEffect(() => {
        console.log('UPDATE')
        setHasMoreT(hasMore);
        if (rows !== null) {
            const tempItems = [...rows];
            setItems(tempItems);
        }
    }, [rows])

    const handleOnClick = (item) => {
        console.log('click');
        const tempItems = [...items];

        tempItems.map((i, index) => {
            if (i.id === item.id) {
                setEdit(true);
                i.edit = !i.edit;
                pauseInterval();
                setPrice((parseFloat(i.attributes.condition) / 100));
                setPriceID(i.id);
            } else {
                i.edit = false;
            }
        })
        setItems(tempItems)
    }

    const handleSave = async (item, update) => {
        console.log('click');

        let updatedAttributes = {
            data: {
                attributes: {
                    condition: parseInt(Math.round(parseFloat(price) * 100 * 10) / 10),
                    product_id: item.attributes.product_id,
                    price_id: item.attributes.priceId
                }
            }
        }

        if (update) {
            updatedAttributes = {
                data: {
                    attributes: {
                        condition: parseInt(Math.round(parseFloat(price) * 100 * 10) / 10)
                    }
                }
            }
        }

        if (update) {

            let {
                response,
                data
            } = await api(process.env.REACT_APP_API_URL + "/alert/" + item.attributes.alertId, 'PATCH', JSON.stringify(updatedAttributes))

            if (!response || response.status == 401) {
                // this.props.sessionGotUpdate(true, true);
                // ReactSession.set('token', undefined);
                // ReactSession.set('refreshToken', undefined);
                // this.props.history.push('/login');
                return;
            }

            if (response) {
                reload(false, 'Dein Alarm wurde erfolgreich aktualisiert');
                setPrice(0);
                const tempItems = [...items];

                tempItems.map((i, index) => {
                    i.edit = false;
                    if (i.id == priceID) {
                        i.attributes.condition = null;
                        setPrice(null);
                        setPriceID(null);
                    }
                })
                setItems(tempItems);
            }
        } else {

            let {
                response,
                data
            } = await api(process.env.REACT_APP_API_URL + "/alerts", 'POST', JSON.stringify(updatedAttributes))


            if (!response || response.status == 401) {
                // this.props.sessionGotUpdate(true, true);
                // ReactSession.set('token', undefined);
                // ReactSession.set('refreshToken', undefined);
                // this.props.history.push('/login');
                return;
            }

            if (response) {
                reload(false, 'Deine Alarm wurde erfolgreich erstellt');
                setPrice(0);
                const tempItems = [...items];

                tempItems.map((i, index) => {
                    i.edit = false;
                    if (i.id == priceID) {
                        i.attributes.condition = null;
                        setPrice(null);
                        setPriceID(null);
                    }
                })
                setEdit(false);
                setItems(tempItems);
            }
        }
        continueInterval();
    }

    const handleKeypress = (e, item, update) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            handleSave(item, update);
        }
    };

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = (id) => {
        setIsHovering(id);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <React.Fragment>
            <div className="container">
                <Hidden smDown>
                    <Grid container spacing={2}
                          style={{
                              paddingTop: 10,
                              display: 'flex',
                              alignItems: 'center',
                              borderBottom: '2px solid #ddd'
                          }}>
                        <Grid item xs={12} sm={2} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            Produkt
                        </Grid>
                        <Grid item xs={12} sm={4} style={{textAlign: 'left', fontWeight: 'bold'}}>

                        </Grid>
                        <Grid item xs={12} sm={ReactSession.get('token') ? 1 : 4} style={{textAlign: 'right', fontWeight: 'bold', paddingRight: 15}}>
                            Preisverlauf
                        </Grid>
                        <Grid item xs={12} sm={2} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Preis*
                        </Grid>
                        {ReactSession.get('token') ? <>
                            <Grid item xs={12} sm={2} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                Wunschpreis
                            </Grid>
                            <Grid item xs={12} sm={1} style={{textAlign: 'right', fontWeight: 'bold'}}>

                            </Grid>
                        </> : null}
                    </Grid>
                </Hidden>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...style, width: 700, padding: 0}}>
                    <h2 style={{textAlign: 'center'}}>Preisverlauf</h2>
                    <Chart priceId={priceId}/>
                </Box>
            </Modal>
            <InfiniteScroll
                dataLength={items?.length ?? 0} //This is important field to render the next data
                next={newData}
                hasMore={hasMoreT}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        {/*<b>No more items to load</b>*/}
                    </p>
                }
                style={{overflow: 'unset'}}
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
            >
                {items && items.map((item, index) => {
                    return (<Grid container direction="row" spacing={2} key={item.id}
                                  style={{
                                      paddingTop: 10,
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderBottom: '2px solid #ddd',
                                      minHeight: '192px'
                                  }}>
                        <Grid item xs={12} md={2} style={{textAlign: 'center', fontWeight: 'bold'}}
                              className='wishlistImage'>
                            <div style={{position: 'relative'}}>
                                <a href={item.attributes.productLink} target={"_blank"}>
                                    <img src={item.attributes.imageUrl} style={{maxWidth: 160, maxHeight: 160, width: 'auto', height: 'auto'}} alt=""
                                         className="imageUrlAjax"/>

                                </a>
                                {item.attributes.percentage > 0 && <p style={{
                                    position: 'absolute',
                                    height: 25,
                                    width: 25,
                                    top: 0,
                                    right: 0,
                                    fontSize: 12,
                                    background: '#F78700',
                                    padding: 10,
                                    borderRadius: 25,
                                    lineHeight: '25px',
                                }}>-{item.attributes.percentage}%</p>}
                            </div>
                            {item.attributes.imageUrl === "" &&
                                <div data-tip data-for="loaderTooltip">
                                    <ReactTooltip id="loaderTooltip" place="top" effect="float">
                                        <span
                                            style={{fontSize: 10}}>Wir recherchieren den aktuellen Preis für Dich.<br/>Bitte habe noch etwas Geduld.</span>
                                    </ReactTooltip>
                                    <MoonLoader speedMultiplier={1} css={override} loading={true} color={'black'}
                                                size={16}/></div>
                            }
                        </Grid>
                        <Grid item xs={12} md={ReactSession.get('token') ? 4 : 7} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <div className="productTitleAjax" style={{minHeight: 25, marginBottom: 20}}>
                                <a style={{lineBreak: 'anywhere'}} href={item.attributes.productLink} target={'_blank'}>{item.attributes.title}</a>
                            </div>

                            <Box sx={{display: {md: 'block', xs: 'none'}}}>
                                <Button variant='outlined'
                                        href={item.attributes.productLink}
                                        target={'_blank'}
                                        onMouseEnter={() => handleMouseEnter(item.id)}
                                        onMouseLeave={() => handleMouseLeave()}
                                        style={{
                                            background: isHovering !== item.id ? '#f87800' : 'green',
                                            border: 0,
                                            minWidth: 100,
                                            marginRight: 2,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 7,
                                            paddingBottom: 7
                                        }}>
                                    <ShoppingCart sx={{fontSize: 20, color: 'white'}}/>&nbsp;<span
                                    style={{color: 'white', fontWeight: 600}}>Kaufen</span>
                                </Button>
                                <Button variant='outlined'
                                        href={process.env.REACT_APP_FRONTEND_URL + '/p/' + item.attributes.product_id}
                                        target={'_blank'}
                                        onMouseEnter={() => handleMouseEnter(item.id + '-DetailButton')}
                                        onMouseLeave={() => handleMouseLeave()}
                                                        style={{
                                                            background: isHovering !== item.id + '-DetailButton' ? '#f87800' : 'green',
                                                            minWidth: 40,
                                                            border: 0,
                                                            marginRight: 2,
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                            paddingTop: 9.25,
                                                            paddingBottom: 9.25
                                                        }}><Info sx={{fontSize: 20, color: 'white'}}/>
                                </Button>
                            </Box>
                            <Box sx={{display: {md: 'none', sm: 'block'}}}>
                                <Button variant='outlined'
                                        href={item.attributes.productLink}
                                        target={'_blank'}
                                        style={{
                                            background: '#f87800',
                                            border: 0,
                                            minWidth: 100,
                                            marginRight: 2,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 7,
                                            paddingBottom: 7
                                        }}>
                                    <ShoppingCart sx={{fontSize: 20, color: 'white'}}/>&nbsp;<span
                                    style={{color: 'white', fontWeight: 600}}>Kaufen</span>
                                </Button>
                                <Button variant='outlined'
                                        href={process.env.REACT_APP_FRONTEND_URL + '/p/' + item.attributes.product_id}
                                        target={'_blank'}
                                        style={{
                                            background: '#f87800',
                                            minWidth: 40,
                                            border: 0,
                                            marginRight: 2,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 9.25,
                                            paddingBottom: 9.25
                                        }}><Info sx={{fontSize: 20, color: 'white'}}/>
                                </Button>
                                <div style={{textAlign: 'right', float: 'right'}}>
                                    {item.attributes.title && <Button variant='outlined'
                                                                      onClick={(e) => {
                                                                          setPriceId(item.attributes.priceId)
                                                                          handleShow(true)
                                                                      }}
                                                                      style={{
                                                                          background: '#f87800',
                                                                          border: 0,
                                                                          minWidth: 100,
                                                                          paddingLeft: 7,
                                                                          paddingRight: 7,
                                                                          paddingTop: 7,
                                                                          paddingBottom: 7
                                                                      }}>
                                <span
                                    style={{
                                        color: 'white',
                                        fontWeight: 600,
                                        textTransform: 'capitalize'
                                    }}>Preisverlauf</span>
                                    </Button>}
                                </div>
                            </Box>


                            <Box sx={{display: {md: 'none', sm: 'block'}}} style={{marginTop: 10}}>
                                {(!item.edit && ReactSession.get('token')) && <Button variant='outlined'
                                                       onClick={() => confirmDelete(item.id)}
                                                       style={{
                                                           background: '#d9534f',
                                                           border: 0,
                                                           minWidth: 40,
                                                           marginRight: 2,
                                                           marginBottom: 10,
                                                           paddingLeft: 0,
                                                           paddingRight: 0,
                                                           paddingTop: 7,
                                                           paddingBottom: 7
                                                       }}>
                                    <Delete sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}

                                <div style={{textAlign: 'right', float: 'right'}} >

                                    {(item.edit && ReactSession.get('token')) &&
                                        <TextField variant="outlined"
                                                   autoFocus
                                                   label={'Preis'}
                                                   value={price ? price : ''}
                                                   style={{height: 34, width: 100, marginLeft: 5, marginRight: 10}}
                                                   InputLabelProps={{
                                                       style: {
                                                           height: 34,
                                                           //...(!focused && { top: `${labelOffset}px` }),
                                                       },
                                                   }}
                                                   inputProps={{
                                                       style: {
                                                           height: 34,
                                                           padding: '0 14px',
                                                       },
                                                   }}
                                                   onChange={(e) => {
                                                       if (e.target.value == '') {
                                                           setPrice('')
                                                       } else {
                                                           let amount = e.target.value.replace(',', '.');

                                                           const regex = /^([0-9]{1,6}([.,][0-9]{0,2})?|[,.][0-9])$/gm;

                                                           let m;

                                                           while ((m = regex.exec(amount)) !== null) {
                                                               if (m.index === regex.lastIndex) {
                                                                   regex.lastIndex++;
                                                               }

                                                               if (!e.target.value || e.target.value == '' || m[0]) {
                                                                   setPrice(m[0]);
                                                               }
                                                           }
                                                       }
                                                   }}
                                                   onKeyPress={(e) => handleKeypress(e, item, item.attributes.condition > 0)}/>}
                                    {(item.edit && ReactSession.get('token')) && <Button variant='outlined' onClick={() => {
                                        handleSave(item, item.attributes.condition > 0);
                                    }} style={{
                                        border: 0,
                                        background: '#d9534f',
                                        minWidth: 40,
                                        marginRight: 2,
                                        marginBottom: 10,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingTop: 7,
                                        paddingBottom: 7
                                    }}><SaveAlt sx={{fontSize: 20, color: 'white'}}/></Button>}


                                    {(!item.edit && ReactSession.get('token')) && item.attributes.condition > 0 &&
                                        <Button variant='outlined'
                                                onClick={() => {
                                                    handleOnClick(item);
                                                }}
                                                style={{
                                                    background: '#d9534f',
                                                    border: 0,
                                                    minWidth: 40,
                                                    marginRight: 2,
                                                    marginBottom: 10,
                                                    paddingLeft: 14,
                                                    paddingRight: 14,
                                                    paddingTop: 2,
                                                    paddingBottom: 3
                                                }}>
                                    <span style={{
                                        paddingTop: 4.5,
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}>{new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.condition / 100))}</span>&nbsp;
                                            <Edit sx={{fontSize: 20, color: 'white'}}/>
                                        </Button>}
                                    {(!item.edit && item.attributes.condition > 0 && ReactSession.get('token')) &&
                                        <Button variant='outlined'
                                                onClick={() => {
                                                    confirmDeleteAlert(item.attributes.alertId);
                                                }}
                                                style={{
                                                    background: '#d9534f',
                                                    border: 0,
                                                    minWidth: 40,
                                                    marginBottom: 10,
                                                    paddingLeft: 14,
                                                    paddingRight: 14,
                                                    paddingTop: 7,
                                                    paddingBottom: 7
                                                }}>
                                            <Clear sx={{fontSize: 20, color: 'white'}}/>
                                        </Button>}

                                    {(!item.edit && ReactSession.get('token')) && (item.attributes.condition < 1 && (priceID !== item.id || priceID === null)) &&
                                        <Button variant='outlined'
                                                onClick={() => {
                                                    handleOnClick(item);
                                                }}
                                                style={{
                                                    background: '#f87800',
                                                    border: 0,
                                                    minWidth: 40,
                                                    marginBottom: 10,
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    paddingTop: 7,
                                                    paddingBottom: 7
                                                }}>
                                            <NotificationAdd sx={{fontSize: 20, color: 'white'}}/>
                                        </Button>}
                                    {!item.edit && ReactSession.get('token') && item.attributes.condition === undefined &&
                                        <MoonLoader speedMultiplier={1} css={override2} loading={true} color={'black'}
                                                    size={16}/>}
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={1} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <Box sx={{display: {md: 'block', sm: 'none', xs: 'none'}}}>
                                {item.attributes.title && <Button variant='outlined'
                                                                  onClick={(e) => {
                                                                      setPriceId(item.attributes.priceId)
                                                                      handleShow(true)
                                                                  }}
                                                                  style={{
                                                                      background: '#f87800',
                                                                      border: 0,
                                                                      minWidth: 100,
                                                                      marginRight: 2,
                                                                      paddingLeft: 7,
                                                                      paddingRight: 7,
                                                                      paddingTop: 7,
                                                                      paddingBottom: 7
                                                                  }}>
                                <span
                                    style={{
                                        color: 'white',
                                        fontWeight: 600,
                                        textTransform: 'capitalize'
                                    }}>Preisverlauf</span>
                                </Button>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2} style={{textAlign: 'right', fontWeight: 'bold', paddingLeft: 20}}>
                            {!item.attributes.invalidAsin && new Intl.NumberFormat('de-DE', {
                                style: 'currency',
                                currency: 'EUR'
                            }).format((item.attributes.price ?? 0) / 100)}
                            {!item.attributes.invalidAsin && item.attributes.lastUpdated &&
                                <p style={{fontSize: 8, fontWeight: '400', textAlign: 'right'}}>Aktualisiert: {moment.utc(item.attributes.lastUpdated).local().fromNow()}</p>
                            }
                            {item.attributes.invalidAsin === 1 && <p>Nicht verfügbar</p>}
                        </Grid>


                        <Hidden smDown>
                            {ReactSession.get('token') ? <Grid item xs={12} md={3} style={{textAlign: 'right', fontWeight: 'bold', float: 'left'}}>
                                {(item.edit && ReactSession.get('token')) &&
                                    <TextField variant="outlined"
                                               autoFocus
                                               label={'Preis'}
                                               value={price ? price : ''}
                                               style={{height: 34, width: 120, marginLeft: 5, marginRight: 5}}
                                               InputLabelProps={{
                                                   style: {
                                                       height: 34,
                                                       //...(!focused && { top: `${labelOffset}px` }),
                                                   },
                                               }}
                                               inputProps={{
                                                   style: {
                                                       height: 34,
                                                       padding: '0 14px',
                                                   },
                                               }}
                                               onChange={(e) => {
                                                   if (e.target.value == '') {
                                                       setPrice('')
                                                   } else {
                                                       let amount = e.target.value.replace(',', '.');

                                                       const regex = /^([0-9]{1,6}([.,][0-9]{0,2})?|[,.][0-9])$/gm;

                                                       let m;

                                                       while ((m = regex.exec(amount)) !== null) {
                                                           if (m.index === regex.lastIndex) {
                                                               regex.lastIndex++;
                                                           }

                                                           if (!e.target.value || e.target.value == '' || m[0]) {
                                                               setPrice(m[0]);
                                                           }
                                                       }
                                                   }
                                               }}
                                               onKeyPress={(e) => handleKeypress(e, item, item.attributes.condition > 0)}/>}
                                {item.edit && ReactSession.get('token') && <Button variant='outlined' onClick={() => {
                                    handleSave(item, item.attributes.condition > 0);
                                }} style={{
                                    border: 0,
                                    background: '#d9534f',
                                    minWidth: 40,
                                    marginRight: 2,
                                    marginBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingTop: 7,
                                    paddingBottom: 7
                                }}><SaveAlt sx={{fontSize: 20, color: 'white'}}/></Button>}


                                {!item.edit && ReactSession.get('token') && item.attributes.condition > 0 && <Button variant='outlined'
                                                                                        onClick={() => {
                                                                                            handleOnClick(item);
                                                                                        }}
                                                                                        style={{
                                                                                            background: '#d9534f',
                                                                                            border: 0,
                                                                                            minWidth: 40,
                                                                                            marginRight: 2,
                                                                                            marginBottom: 10,
                                                                                            paddingLeft: 14,
                                                                                            paddingRight: 14,
                                                                                            paddingTop: 2,
                                                                                            paddingBottom: 3
                                                                                        }}>
                                    <span style={{
                                        paddingTop: 4.5,
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}>{new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.condition / 100))}</span>&nbsp;
                                    <Edit sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}
                                {!item.edit && ReactSession.get('token') && item.attributes.condition > 0 &&
                                    <Button variant='outlined'
                                            onClick={() => {
                                                confirmDeleteAlert(item.attributes.alertId);
                                            }}
                                            style={{
                                                background: '#d9534f',
                                                border: 0,
                                                minWidth: 40,
                                                marginRight: 20,
                                                marginBottom: 10,
                                                paddingLeft: 14,
                                                paddingRight: 14,
                                                paddingTop: 7,
                                                paddingBottom: 7
                                            }}>
                                        <Clear sx={{fontSize: 20, color: 'white'}}/>
                                    </Button>}


                                {!item.edit && ReactSession.get('token') && (item.attributes.condition != undefined && item.attributes.condition < 1 && priceID !== item.id) &&
                                    <Button variant='outlined'
                                            onClick={() => {
                                                handleOnClick(item);
                                            }}
                                            style={{
                                                background: '#f87800',
                                                border: 0,
                                                minWidth: 40,
                                                marginRight: 20,
                                                marginBottom: 10,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingTop: 7,
                                                paddingBottom: 7
                                            }}>
                                        <NotificationAdd sx={{fontSize: 20, color: 'white'}}/>
                                    </Button>}
                                {!item.edit && ReactSession.get('token') && item.attributes.condition == undefined &&
                                    <MoonLoader speedMultiplier={1} css={override2} loading={true} color={'black'}
                                                size={16}/>}

                                {!item.edit && ReactSession.get('token') && <Button variant='outlined'
                                                       onClick={() => confirmDelete(item.id)}
                                                       style={{
                                                           background: '#d9534f',
                                                           border: 0,
                                                           minWidth: 40,
                                                           marginRight: 2,
                                                           marginBottom: 10,
                                                           paddingLeft: 0,
                                                           paddingRight: 0,
                                                           paddingTop: 7,
                                                           paddingBottom: 7
                                                       }}>
                                    <Delete sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}
                            </Grid> : null}
                        </Hidden>
                    </Grid>)
                })}
            </InfiniteScroll>
        </React.Fragment>
    );
}
