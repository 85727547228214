import React, {useEffect} from 'react';

import Container from '@material-ui/core/Container';
import {Image} from "@mui/icons-material";
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import base64 from 'base-64'
import {ReactSession} from 'react-client-session';
import useFetch from "./UseFetch";
import {toast} from "react-toastify";

export default function PasswortVergessen(props) {

    useEffect(() => {
        document.title = 'Passwort vergessen';
    }, []);

    const api = useFetch();
    const [onUpdate, setOnUpdate] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);

    const history = useHistory();

    const updatePassword = async () => {
        console.log('updatePassword');

        if (validate()) {
                let updatedAttributes = {
                    email: email.trim()
                }

                let url = process.env.REACT_APP_API_URL + '/user/password-forgotten';
                const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response && data && data.data && data.data.id) {
                    ReactSession.set('userId', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    history.push('/');
                    notify('Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um dein Passwort zu ändern');
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 403) {
                    setEmailError("Unbekannte E-Mail-Adresse");
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 400) {
                    setEmailError("Fehlerhafte E-Mail-Adresse");
                } else if ((data && data.errors && data.errors[0]) || response.error) {
                    notify('API Fehler: ' + response.status, true);
                }
        }

    };

    const validate = (send = false) => {
        var error = false;
        if (email === '' ||(send && !email)) {
            setEmailError('Email darf nicht leer sein!');
            error = true;
        } else {
            setEmailError(undefined);
        }

        return !error;

    };

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    backgroundColor: '#FFFFFF',
                }}>
                <div
                    className='jumbotron'
                    style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        paddingBottom: 10,
                    }}>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: 20,
                        }}>
                        Passwort vergessen:
                    </p>
                </div>
                <React.Fragment>
                    <TextField
                        label="E-Mail"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailError(true);
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value);
                        }}
                        style={{width: '70%', margin: 20}}
                        value={email}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={emailError}
                        helperText={emailError}
                    />                    
                    <Button
                        style={{
                            width: '70%',
                            backgroundColor: '#f87800',
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        disabled={onUpdate}
                        onClick={updatePassword}>
                        <p style={{textAlign: 'center'}}>Neues Passwort anfordern</p>
                    </Button>
                </React.Fragment>
            </div>
        </Container>
    );
}
