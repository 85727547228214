import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import BaseBreadcrumbs from "./BaseBreadcrumbs";
import ApiDataComponent from "./core/ApiDataComponent";
import AlertsDatagrid from "./AlertsDatagrid";
import Button from "@mui/material/Button";
import base64 from 'base-64'
import useCollapse from 'react-collapsed';
import {Grid, TextField} from "@material-ui/core";
import {ReactSession} from "react-client-session";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {Autocomplete} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import useFetch from "./UseFetch";
import {confirmAlert} from "react-confirm-alert";
import ReactGA from "react-ga4";

function Collapsible(props) {
    const history = useHistory();
    const {isExpanded, priceInit, productInit, productInputValueInit, onUpdate, api, location, match, reload} = props;

    useEffect(() => {
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);

    useEffect(async () => {
        if (ReactSession.get('token') === undefined) {

            if (!location.pathname && !match.params.id) {
                history.push('/login');
            } else {
                history.push({
                    pathname: '/login',
                    state: {
                        from: {
                            pathname: location.pathname,
                            id: match.params.id
                        }
                    }
                });
            }
        } else if (history.location.state && !history.location.state.data.id) {
            let url = process.env.REACT_APP_API_URL + '/alerts';
            const {response, data} = await api(url);
            console.log(response.status);
            if (response.status != 401) {

                confirmAlert({
                    title: 'Bestätigung',
                    message: 'Möchtest Du diesen Alarm wirklich löschen?',
                    buttons: [
                        {
                            label: 'Ja',
                            onClick: async () => {
                                let url = process.env.REACT_APP_API_URL + '/alert' + '/' + history.location.state.from.id;
                                const {response, data} = await api(url, 'DELETE');
                                if (response && response.status == 204) {
                                    window.history.replaceState({}, document.title)
                                    reload(false, 'Dein Alarm wurde erfolgreich gelöscht')
                                } else if (response && response.status == 404) {
                                    reload(false, 'Dieser Alarm existiert nicht oder Dir fehlen die Berechtigungen um diesen zu löschen', true)
                                }
                            }
                        },
                        {
                            label: 'Nein',
                            onClick: async () => {
                                window.history.replaceState({}, document.title)
                            }
                        }
                    ],
                    overlayClassName: "overlay-custom-class-name"
                });
            }
        } else if (match.params.id) {
            let url = process.env.REACT_APP_API_URL + '/alerts';
            const {response, data} = await api(url);
            console.log(response.status);
            if (response.status != 401) {
                confirmAlert({
                    title: 'Bestätigung',
                    message: 'Möchtest Du diesen Alarm wirklich löschen?',
                    buttons: [
                        {
                            label: 'Ja',
                            onClick: async () => {
                                let url = process.env.REACT_APP_API_URL + '/alert' + '/' + match.params.id;
                                const {response, data} = await api(url, 'DELETE');
                                if (response && response.status == 204) {
                                    reload(false, 'Dein Alarm wurde erfolgreich gelöscht')
                                } else if (response && response.status == 404) {
                                    reload(false, 'Dieser Alarm existiert nicht oder Dir fehlen die Berechtigungen um diesen zu löschen', true)
                                } else {
                                    console.log(response.status);
                                    reload(false, 'Dieser Alarm existiert nicht oder Dir fehlen die Berechtigungen um diesen zu löschen', true)
                                }
                                history.push('/alarme');

                            }
                        },
                        {
                            label: 'Nein',
                            onClick: async () => {
                                history.push('/alarme');

                            }
                        }
                    ],
                    overlayClassName: "overlay-custom-class-name"
                });
            }
        } else if (match.params.type) {
            window.history.replaceState({}, document.title)
            onUpdate('isExpanded', true);

        } else if (history.location.pathname.includes('/alarme/')) {
            history.push('/alarme');
        }

    }, []);

    const [price, setPrice] = useState(priceInit ?? '');
    const [product, setProduct] = useState(productInit ?? null);
    const [productInputValue, setProductInputValue] = useState(productInputValueInit ?? '');
    const [priceId, setPriceId] = useState(null);
    const [autocompleteList, setAutocompleteList] = useState([]);
    const [productError, setProductError] = useState('');


    const {getCollapseProps} = useCollapse({isExpanded});


    useEffect(() => {
        if (!isExpanded) {
            setPrice('');
            setProduct(null);
            setProductInputValue('');
        }
    }, [isExpanded])

    useEffect(() => {
        onUpdate('price', price);
    }, [price])

    useEffect(() => {
        onUpdate('product', product);
    }, [product])

    useEffect(() => {
        onUpdate('productInputValue', productInputValue);
    }, [productInputValue])

    useEffect(() => {
        onUpdate('price_id', priceId);
    }, [priceId])

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            props.createAlert();
        }
    };

    return (
        <div className="collapsible" style={{minHeight: 20}}>
            <div {...getCollapseProps()} style={{display: isExpanded ? 'block' : 'none'}}>
                <Grid container
                      style={{marginTop: 10, minHeight: 20, border: '1px solid rgb(221, 221, 221)', marginBottom: 10}}>
                    <Grid item xs={12} md={6} className="content" style={{padding: 10}}>
                        <TextField variant="outlined"
                                   label={'Preis'}
                                   value={price}
                            /* styles the wrapper */
                                   style={{height: 51.5}}

                            /* styles the label component */
                                   InputLabelProps={{
                                       style: {
                                           height: 51.5,
                                           // ...(!focused && { top: `${labelOffset}px` }),
                                       },
                                   }}

                            /* styles the input component */
                                   inputProps={{
                                       style: {
                                           height: 51.5,
                                           padding: '0 14px',
                                       },
                                   }}
                                   onChange={(e) => {
                                       if (e.target.value == '') {
                                           setPrice('')
                                       } else {
                                           let amount = e.target.value.replace(',', '.');

                                           const regex = /^([0-9]{1,6}([.,][0-9]{0,2})?|[,.][0-9])$/gm;

                                           let m;

                                           while ((m = regex.exec(amount)) !== null) {
                                               if (m.index === regex.lastIndex) {
                                                   regex.lastIndex++;
                                               }

                                               if (!e.target.value || m[0]) {
                                                   setPrice(m[0]);
                                               }
                                           }
                                       }
                                   }}
                                   onKeyPress={handleKeypress}
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={12} md={6} className="content" style={{padding: 10}}>
                        <Autocomplete options={autocompleteList}
                                      getOptionLabel={(option) => {
                                          return option.title;
                                      }}
                                      name={''}
                                      freeSolo
                                      value={product}
                                      onChange={(event, newValue) => {
                                          setProduct(newValue);
                                          setPriceId(newValue.price_id);
                                      }}
                                      selectOnFocus
                                      clearOnBlur
                                      handleHomeEndKeys
                                      inputValue={productInputValue}
                                      onInputChange={async (event, newInputValue) => {
                                          setProductInputValue(newInputValue);

                                          let url = process.env.REACT_APP_API_URL + '/products?include=price' + '&filter%5Btitle%5D%5Blike%5D=' + newInputValue + '&filter%5BresellerId%5D%5Beq%5D=1&include=prices';
                                          let {response, data} = await api(url);

                                          if (response && data) {
                                              let values = [];
                                              for (let k in data.data) {
                                                  if (data.data[k].attributes.title != '') {
                                                      values.push({
                                                          title: data.data[k].attributes.title,
                                                          inputValue: data.data[k].id,
                                                          price_id: data.included.filter((i => i.type == 'prices' && i.attributes.product_id == data.data[k].id))[0].id
                                                      });
                                                  }
                                              }

                                              setAutocompleteList(values);
                                          }
                                      }}
                                      onKeyPress={handleKeypress}
                                      renderInput={(params) =>
                                          <TextField {...params}
                                                     label={'Produkt'}
                                              // onBlur={this.onValidate}
                                                     variant="outlined"
                                                     error={productError.length > 0}
                                                     helperText={'Gib mindesten 1 Buchstaben ein um die Suche zu starten'}
                                                     style={{margin: 0, padding: 0}}
                                          />}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

class Alerts extends ApiDataComponent {

    api = undefined

    constructor(props) {
        super(props);

        this.reload = this.reload.bind(this);
        this.newData = this.newData.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.pauseInterval = this.pauseInterval.bind(this);
        this.continueInterval = this.continueInterval.bind(this);
        this.api = useFetch();

        this.state = {
            initialDataUrl: props.initialDataUrl + '?include=product,price&page%5Bsize%5D=10&page%5Bnumber%5D=1',
            pageNumber: 1,
            hasMore: true,
            loading: false,
            rows: null,
            isExpanded: false,
            priceInit: '',
            productInit: null,
            productInputValueInit: '',
            dataId: props.history.location.state?.data.id ?? null
        };
        document.title = 'Alarme';
    }

    componentDidMount() {
        this.getData();
        if (this.state.data) {
            this.setState({isExpanded: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data && this.props.data !== '') {

            let temp = this.props.data.match(/\bhttps?:\/\/\S+/gi);
            if (temp) temp = temp[0];
            this.setState({
                isExpanded: true,
                url: temp
            }, () => {

            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    getData = async (getMore = false) => {
        let token = ReactSession.get('token');
        let total = 0;
        let timer = false;
        let moreData = true;
        let rows = [];
        let insgesamt = [];
        // if (!this.state.newData) {
        //     if (this.state.rows !== null) rows = [...this.state.rows];
        //     else rows = [];
        //     this.setState({
        //         newData: false
        //     })
        // }

        for (let i = 1; i < this.state.pageNumber + 1; i++) {
            let url = this.props.initialDataUrl + '?include=product,price&page%5Bsize%5D=10&page%5Bnumber%5D=' + i;
            if (this.state.dataId) {
                url = this.props.initialDataUrl + '?include=product,price&filter%5Bid%5D=' + this.state.dataId;
            }

            let {response, data} = await this.api(url);

            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                this.props.history.push('/login');
                return;
            }

            if (response && data) {


                data.data.map((item, index) => {

                    item.attributes.title = data.included.filter((i => i.type == 'products' && i.id == item.attributes.product_id))[0].attributes.title ?? null;
                    item.attributes.price = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.price;
                    item.attributes.percentage = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.percentage;
                    item.attributes.invalidAsin = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.invalidAsin;
                    item.attributes.lastUpdated = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.lastUpdated;
                    item.attributes.priceId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].id;
                    item.attributes.productLink = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.productLink;
                    item.attributes.imageUrl = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.imageUrl;
                    item.attributes.resellerId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.resellerId;

                    if (item.attributes.title === '' || item.attributes.price == null) {
                        timer = true;
                    }

                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        let edit = rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit;
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit = edit;
                    }
                })

                insgesamt = insgesamt.concat(data.data);


                total = data.meta.pagination.total;

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

                total = data.meta.pagination.total;
                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

                if (timer && !this.state.interval) {
                    const interval = setInterval(() => {
                        this.getData();
                    }, 1000);
                    this.setState({interval});
                } else if (!timer && this.state.interval) {
                    clearInterval(this.state.interval);
                    this.setState({interval: undefined});
                }

            } else {
                this.notify('API Fehler: ' + response.status, true);
            }
        }

        this.setState({
            rows: rows,
            hasMore: moreData,
            total: total,
            loading: true
        }, () => {
            // if (rows.length < insgesamt.length) {
            //     let temp = [];
            //     rows.map((item, index) => {
            //         if (insgesamt.filter(i => i.id === item.id).length > 0) {
            //             temp.push(item);
            //         }
            //     })
            //     if (temp) {
            //         this.setState({
            //             rows: temp,
            //         })
            //     }
            // }
        })


    }

    notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    reload(newData = false, message, error = false) {
        this.setState({
            isExpanded: false,
            newData: newData,
        }, async () => {
            await this.getData(newData);
            if (message) {
                this.notify(message, error);
            }
        })
    }

    setLoading() {
        this.setState({
            loading: false
        })
    }

    newData() {
        if (this.state.hasMore) {
            let temp = ++this.state.pageNumber;
            this.setState({
                pageNumber: temp,
            }, () => {
                this.getData(this.state.hasMore);
            })
        } else {
            this.getData()
        }
    }

    createAlert = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    condition: Math.floor(this.state.price * 100),
                    product_id: this.state.product.inputValue,
                    price_id: this.state.price_id
                },
                relationships: {}
            }
        }

        let url = process.env.REACT_APP_API_URL + "/alerts";
        const {response, data} = await this.api(url, 'POST', JSON.stringify(updatedAttributes));
        if (response && response.status != '404' && data) {
            this.reload(false, 'Dein Alarm wurde erfolgreich angelegt');
            this.setState({
                price: null,
                product: null,
                productInputValue: ''
            })
            this.props.history.push('/alarme')
        } else if (response.status == '404') {
            this.reload(false, "Alarm existiert bereits", true);
        }
    }

    onUpdate = (type, value) => {
        this.setState({
            [type]: value
        })
    }


    pauseInterval = () => {
        console.log('pause')
        clearInterval(this.state.interval);
        this.setState({interval: undefined});
    }

    continueInterval = () => {
        console.log('continue')
        const interval = setInterval(() => {
            this.getData();
        }, 5000);
        this.setState({interval});
    }

    render() {
        return (
            <Container maxWidth="lg">
                <BaseBreadcrumbs routes={[{value: '', label: 'Home'}, {value: 'alarme', label: 'Alarme'}]}/>
                {this.state.dataId && <div style={{width: '100%', marginBottom: 5}}>
                    <Button variant={'outlined'} onClick={() => {
                        this.setState({
                            dataId: null
                        }, () => {
                            this.getData();
                        })
                        window.history.replaceState({}, document.title)

                    }}>Filter zurücksetzen</Button>
                </div>}
                {!this.state.isExpanded &&
                    <Button variant={'outlined'}
                            onClick={() => this.setState({isExpanded: !this.state.isExpanded})}>Alarm
                        anlegen</Button>}
                {this.state.isExpanded &&
                    <React.Fragment>
                        <Button variant={'contained'} color={'success'}
                                disabled={(this.state.price ? this.state.price.length === 0 : true) || (this.state.product ? this.state.product.length === 0 : true)}
                                onClick={() => this.createAlert()}>Alarm anlegen</Button>
                        <Button variant={'contained'} color={'error'} style={{marginLeft: 5}}
                                onClick={() => this.setState({
                                    isExpanded: false,
                                    price: '',
                                    product: null,
                                    productInputValue: ''
                                })}><Cancel style={{opacity: 0.8}}/></Button>
                    </React.Fragment>}
                <Collapsible reload={this.reload} location={this.props.location} match={this.props.match}
                             createAlert={this.createAlert} isExpanded={this.state.isExpanded}
                             onUpdate={this.onUpdate} priceInit={this.state.price} productInit={this.state.product}
                             productInputValueInit={this.state.productInputValue} api={this.api}/>
                <AlertsDatagrid pauseInterval={this.pauseInterval} continueInterval={this.continueInterval}
                                notify={this.notify} rows={this.state.rows} newData={this.newData}
                                hasMore={this.state.hasMore} loading={this.state.loading}
                                setLoading={this.setLoading}
                                initialDataUrl={this.state.initialDataUrl} reload={this.reload}/>
            </Container>
        );
    }
}

export default withRouter(Alerts)
