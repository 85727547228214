import React, {useEffect} from 'react';
import Container from '@material-ui/core/Container';
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory, useLocation} from "react-router-dom";
import {ReactSession} from 'react-client-session';
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import ReactGA from "react-ga4";
import Link from "@mui/material/Link";

export default function Login(props) {
    const {sessionGotUpdate} = props;

    const [username, setUsername] = React.useState('');
    const [usernameError, setUsernameError] = React.useState(undefined);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(undefined);
    const [onLogin, setOnLogin] = React.useState(false);
    const [onRegistration, setOnRegistration] = React.useState(false);

    const history = useHistory();
    const api = useFetch();

    const [newHistory, setNewHistory] = React.useState(history.location.state);
    const location = useLocation();

    useEffect(() => {
        document.title = 'Login';
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);

    const login = async () => {
        console.log('login');

        if (validate()) {
            setOnLogin(true);

            let url = process.env.REACT_APP_API_URL + '/user/login';
            let {response, data} = await api(url, 'POST',
                JSON.stringify({
                    username: username.trim(),
                    password: password.trim()
                })).catch((error) => {
                    console.log(error);
                    return {
                        response: {
                            status: error.message,
                            error: true
                        },
                        data: null
                    }
            });

            if (response && data) {

                if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User does not exist") {
                    setUsernameError('Benutzer existiert nicht');
                } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User password is wrong") {
                    setPasswordError('Passwort ist fehlerhaft');
                } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User not confirmed") {
                    setUsernameError((<span>Benutzer ist nicht bestätigt - <Link style={{cursor: 'pointer'}} onClick={() => {
                        register();
                    }}>erneut versenden</Link></span>));
                } else if (data.errors && data.errors[0]) {
                    notify('API Fehler: ' + response.status, true);
                } else if (data.data && data.data.token) {
                    console.log('right credentials');
                    ReactSession.set('token', data.data.token);
                    ReactSession.set('refreshToken', data.data.refreshToken);
                    ReactSession.set('userId', data.data.user.id);
                    ReactSession.set('username', data.data.user.username);

                    if (newHistory && newHistory.from && newHistory.from.pathname && newHistory.from.pathname.includes('alarme')) {
                        window.history.replaceState({}, document.title)
                        history.push({
                            pathname: '/alarme',
                            state: {
                                from: {
                                    id: newHistory.from.id
                                }
                            }
                        });
                    } else {
                        history.push('/');
                    }
                    sessionGotUpdate();
                    return;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }

            setOnLogin(false);

        } else {
            validate(true);
        }
    };

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const validate = (send = false) => {
        let error = false;
        if (username === '' || (send && !username)) {
            error = true;
            setUsernameError('Username darf nicht leer sein!');
        } else {
            setUsernameError(undefined);
        }
        if (password === '' || (send && !password)) {
            error = true;
            setPasswordError('Password darf nicht leer sein!');
        } else {
            setPasswordError(undefined);
        }

        return !error;
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            login();
        }
    };


    const register = async () => {
        console.log('register');

        if (
           username && !usernameError
        ) {

            let url = process.env.REACT_APP_API_URL + '/user/register';
            const {response, data} = await api(url, 'POST', JSON.stringify({
                data: {
                    attributes: {
                        username: username.trim(),
                        password: password.trim()
                    }
                }
            }));
            console.log(response.status);

            if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                console.log('wrong credentials');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                setPasswordError(data.errors[0].meta.password);
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                setUsernameError(data.errors[0].meta.username);
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            } else if (data.data && data.data.id) {
                console.log('right credentials');
                history.push('/login');
                notify('Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
            }
        }
    };

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    backgroundColor: '#FFFFFF',
                }}>
                <div
                    className='jumbotron'
                    style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        paddingBottom: 10,
                    }}>
                    <img
                        src={require('./../images/icon.png')}
                        height={120}
                        style={{backgroundColor: 'white'}}
                    />
                    {/*<h1 style={{backgroundColor: 'white'}}>*/}
                    {/*    nicer-<span style={{color: '#f87800'}}>pricer</span>*/}
                    {/*</h1>*/}
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: 20,
                        }}>
                        Login:
                    </p>
                </div>
                <React.Fragment>
                    <TextField
                        label="Benutzername oder E-Mail"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setUsernameError(true);
                            } else {
                                setUsernameError(false);
                            }
                            setUsername(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={username}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={usernameError ? true : false}
                        helperText={usernameError}
                    />
                    <TextField
                        label="Passwort"
                        type="password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setPasswordError(true);
                            } else {
                                setPasswordError(false);
                            }
                            setPassword(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                        style={{width: '70%', margin: 20}}
                        value={password}
                        theme={{
                            colors: {
                                primary: '#f87800',
                                placeholder: '#000000',
                                text: '#000000',
                                underlineColor: '#000000',
                                background: '#FFFFFF',
                            },
                        }}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                    />
                    <Button
                        style={{
                            width: '70%',
                            backgroundColor: '#f87800',
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 40,
                            marginBottom: 40,
                        }}
                        // disabled={onLogin}
                        onClick={login}>
                        <p style={{textAlign: 'center'}}>Login</p>
                    </Button>
                    <Button style={{width: '70%', margin: 1}} onClick={(e) => {
                        setUsername(undefined);
                        setPassword(undefined);
                        console.log('passwort-vergessen')
                        history.push('/passwort-vergessen');
                    }}>
                        <p style={{textAlign: 'center'}}>Passwort vergessen</p>
                    </Button>
                    <Button
                        style={{width: '70%', margin: 1, marginBottom: 20}}
                        onClick={(e) => {
                            setUsername(undefined);
                            setPassword(undefined);
                            console.log('registration')
                            history.push('/registration');
                        }}
                    >
                        <p style={{textAlign: 'center'}}>Registrierung</p>
                    </Button>
                </React.Fragment>
            </div>
        </Container>
    );
}
