import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {useHistory} from "react-router-dom";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function BaseBreadcrumbs(props) {
    const {routes} = props;
    const history = useHistory();

    let breadcrumbs;
    if (routes) {
        breadcrumbs = routes.map((item, index) => {
            if (index === routes.length - 1) {
                return <Typography key={item.value} color="text.primary">{item.label}</Typography>
            } else {
                return <Link key={item.value} underline="hover" color="inherit" onClick={() => history.push('/' + item.value)}>
                    {item.label}
                </Link>
            }
        })
    }



    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginTop:20, marginBottom: 50}}>
                {breadcrumbs}
            </Breadcrumbs>
        </div>
    );
}
