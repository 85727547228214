import React, {Component, useEffect, useRef, useState} from 'react';

import Container from '@material-ui/core/Container';
import BaseBreadcrumbs from "./BaseBreadcrumbs";
import {withRouter} from "react-router-dom";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import useFetch from "./UseFetch";
import {Card, CardContent, CardMedia, Divider, Grid} from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LineChart from "./LineChart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReactGA from "react-ga4";
import {Delete, Edit, ShoppingCart} from "@mui/icons-material";
import Button from "@mui/material/Button";
import moment from "moment";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

function Chart(props) {
    const {priceHistory, testWidth} = props;
    const [data, setData] = useState([]);

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [width, setWidth] = useState(-1);

    const ref = useRef(null);


    useEffect(() => {
        window.addEventListener('resize', handleResize)
        regenerateData();
    }, []);


    const handleResize = () => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth);
        }
    }

    function regenerateData() {

        let minPrice = null;
        let maxPrice = null;
        let minDate = null;
        let maxDate = null;


        let tempData = [];
        priceHistory.map((item, index) => {
            if (minDate === null || new Date(item.createdAt) < minDate) {
                minDate = new Date(item.createdAt);
            }
            if (maxDate === null || new Date(item.createdAt) > maxDate) {
                maxDate = new Date(item.createdAt);
            }
            if (minPrice === null || item.value < minPrice) {
                minPrice = item.value;
            }
            if (maxPrice === null || item.value > maxPrice) {
                maxPrice = item.value;
            }
            tempData.push({
                date: new Date(item.createdAt),
                value: item.value / 100,
                tooltipContent: `<div style="text-align: left"><b>Preis: </b>&nbsp;&nbsp;${item.value / 100} €<br><b>Datum: </b>${(new Date(item.createdAt)).toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })}</div>`,
            });
        })
        setMinPrice((minPrice / 100).toFixed(2));
        setMaxPrice((maxPrice / 100).toFixed(2));
        setMinDate(minDate.toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }));
        setMaxDate(maxDate.toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }));
        setData(tempData);

    }

    console.log(testWidth)

    return (
        <div ref={ref}>
            {data && data.length > 0 ? <LineChart
                svgProps={{
                    margin: {top: 20, bottom: 10, left: 60, right: 0},
                    width: testWidth ? testWidth > 1280 ? (1280-550) : testWidth > 950 ? (testWidth - 550) : (testWidth - 140) : 600,
                    height: 150,
                }}
                // axisProps={{
                //     xLabel: 'X Axis',
                //     yLabel: 'Y Axis',
                // }}
                data={data}
                strokeWidth={3}
            /> : <p style={{textAlign: 'center'}}>loading</p>}

            {minPrice && maxPrice && minDate && maxDate ?
                <Grid container spacing={2} style={{marginBottom: 10, marginTop: testWidth < 950 ? 10 : 0}}>
                    <Grid item xs={ref.current ? (ref.current.offsetWidth < 450) ? 12 : 6 : 6} style={{
                        textAlign: 'center'
                    }}>
                         <span style={{display: "inline-flex", alignItems: "center"}}>
                             <ArrowDownwardIcon sx={{fontSize: 26, color: 'green'}}/>&nbsp;
                             <b>{minPrice} €</b>&nbsp;&nbsp;
                             <ArrowUpwardIcon sx={{fontSize: 26, color: 'red'}}/>&nbsp;<b>{maxPrice} €</b>
                        </span>
                    </Grid>
                    <Grid item xs={ref.current ? (ref.current.offsetWidth < 450) ? 12 : 6 : 6} style={{textAlign: 'center'}}>
                         <span style={{display: "inline-flex", alignItems: "center"}}>
                             <DateRangeIcon sx={{fontSize: 26}}/>&nbsp;&nbsp;
                             <b>{minDate} - {maxDate}</b>
                        </span>
                    </Grid>
                </Grid>
                : undefined}
        </div>
    )
}

class ProductDetail extends Component {

    api = undefined
    mount = false

    constructor(props) {
        super(props);

        this.mount = true;
        this.api = useFetch();
        window.addEventListener('resize', this.handleResize)
        this.state = {
            productNotFound: false,
            width: window.innerWidth,
            images: [],
            rows: [],
            id: props.match.params.id
        };
    }

    handleResize = () => {
        if (this.mount && window && window.innerWidth) {
            this.setState({
                width: window.innerWidth
            })
        }


    }

    componentDidMount() {
        if (this.mount) {
            this.getData();
            ReactGA.send('https://nicer-pricer.de' + this.props.location.pathname + this.props.location.search);
            console.log(process.env.REACT_APP_FRONTEND_URL + this.props.location.pathname + this.props.location.search);
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    getData = async (getMore = false) => {
        console.log(this.state.id);
        let rows = [];
        let url = this.props.initialDataUrl + 'product/' + this.state.id + '/include/items';
        let {response, data} = await this.api(url);

        // if (!response || response.status == 401) {
        //     this.props.sessionGotUpdate(true, true);
        //     ReactSession.set('token', undefined);
        //     ReactSession.set('refreshToken', undefined);
        //     this.props.history.push('/login');
        //     return;
        // }
        console.log(data);
        if (response && data && data.data && data.data.product && data.data.product.productSeoTitle && window.location.href.indexOf('/p/' + this.state.id + '/' + data.data['product']['productSeoTitle']) < 0) {
            this.props.history.push('/p/' + this.state.id + '/' + data.data.product.productSeoTitle)
            this.getData();
        } else if (response && data && data.data) {
            if (data.data['product'] && data.data['product']['productSeoTitle']) {
                if (window.location.href.indexOf('/p/' + this.state.id + '/' + data.data['product']['productSeoTitle']) < 0) {
                    this.props.history.push('/p/' + this.state.id + '/' + data.data['product']['productSeoTitle']);
                }
            } else {
                this.props.history.push('/p/' + this.state.id);
            }

            let productFiles = [];

            if (data['data']['product'] && data['data']['product']['ean']) {
                let url2 = 'https://api.nicer-pricer.de/products?include=files&filter%5Bean%5D=' + encodeURIComponent(data['data']['product']['ean']);

                let temp = await this.api(url2);

                let data2 = temp.data;
                if (data2 && data2.included) {
                    for (const [key, value] of Object.entries(data2.included)) {
                        let file = {};
                        file['id'] = value.id;
                        if (value.attributes['objectType'] !== undefined) {
                            file['objectType'] = value.attributes['objectType'];
                        }


                        productFiles.push({
                            original: 'https://api.nicer-pricer.de/file/' + file['id'] + '/download/content',
                            thumbnail: 'https://api.nicer-pricer.de/file/' + file['id'] + '/download/content',
                        })
                    }
                }
            }


            //data.data['productFiles'] = productFiles;
            this.setState({
                images: productFiles
            })




            rows.push(data.data);

            if (data.data['product'] && data.data['product']['title']) {
                document.title = 'Produkt: ' + data.data['product']['title'];
            }

            if (ReactSession.get('token') !== undefined) {
                let url = this.props.initialDataUrl + 'product/' + this.state.id + '/include/itemslogged';
                let temp = await this.api(url);
                console.log(temp.data);
                if (temp.data && temp.data.data) {
                    rows.push(temp.data.data);
                }
            }

        }
        console.log(rows);
        if (rows.length === 0 || (rows.length > 0 && (rows[0]['product'] == null))) {
            //this.props.history.push('/404');
            this.setState({
                productNotFound: true
            })
        } else {
            this.setState({
                rows,
                productNotFound: false
            }, () => {
                console.log(this.state.rows);
            })
        }
    }

    notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    reload(newData = false, message) {
        this.setState({
            isExpanded: false,
            newData: newData,
        }, async () => {
            await this.getData(newData);
            if (message) {
                this.notify(message);
            }
        })
    }

    render() {

        console.log(this.state.rows && this.state.rows[0]);

        return (
            <Container maxWidth="lg">
                {this.mount &&
                    <React.Fragment>
                        <BaseBreadcrumbs
                            routes={[{value: '', label: 'Home'}, {value: 'product', label: 'Produkt'}, {
                                value: 'name',
                                label: this.state.id ? this.state.id : ''
                            }]}/>
                    </React.Fragment>}

                {(this.state && this.state.rows && !this.state.productNotFound && this.state.rows.length > 0 && this.state.rows[0]['product']['ean'] === '') &&
                    <>
                        <div style={{textAlign: 'left', minHeight: 61, padding: '0 0 0 10px'}}>
                            {this.state.rows[0]['productBefore'] &&
                                <Button style={{textAlign: 'left', maxWidth: 80}} onClick={() => {
                                    this.props.history.push('/p/' + this.state.rows[0]['productBefore']);
                                    this.setState({
                                        id: this.state.rows[0]['productBefore']
                                    }, () => {
                                        this.getData();
                                    })

                                }}>
                                    Vorheriges Produkt
                                </Button>}

                            <span style={{float: 'right'}}>
                            {this.state.rows[0]['productAfter'] &&
                                <Button style={{maxWidth: 80, textAlign: 'right'}} onClick={() => {
                                    this.props.history.push('/p/' + this.state.rows[0]['productAfter']);
                                    this.setState({
                                        id: this.state.rows[0]['productAfter']
                                    }, () => {
                                        this.getData();
                                    })
                                }}>
                                    Nächstes Produkt
                                </Button>}
                                </span>
                        </div>
                        <Card sx={{
                            // maxWidth: 750,
                            // width: 400,
                            margin: '0 auto 50px',
                            border: '2px solid rgba(211,211,211,0.4)',
                            // boxShadow: '0px 0px 15px 6px rgb(46 61 73 / 15%)',
                            boxShadow: 'unset',
                            // transition: 'all 0.3s ease'
                            // padding: '15px 25px',
                        }}>
                            <CardContent style={{
                                padding: 0,
                                display: (!this.state.width || this.state.width < 950) ? 'block' : 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                <div style={{padding: (!this.state.width || this.state.width < 950) ? 10 : '10px 0 10px 10px', minWidth: (!this.state.width || this.state.width < 950) ? 0 : 360, width: (this.state.width && this.state.width < 950) ? 'calc(100% - 20px)' : 'auto' }}>
                                    <div style={{minHeight: 370}}>
                                        <div style={{height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <CardMedia
                                            component="img"
                                            width="320"
                                            style={{
                                                // height: 320,
                                                height: '100%',
                                                objectFit: 'contain',
                                                width: (!this.state.width || this.state.width < 950) ? 'auto' : '100%',
                                                maxWidth: (!this.state.width || this.state.width < 950) ? '100%' : 320,
                                                maxHeight: 320,
                                                margin: 'auto',
                                                cursor: 'pointer'}}
                                            image={this.state.rows[0]['price']['imageUrl']}
                                            onMouseDown={(e) => {
                                                if (e.button === 1) {
                                                    window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                                }
                                            }}
                                            onClick={(e) => {
                                                window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                            }}
                                        />
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'block',
                                        position: 'relative',
                                        // justifyContent: 'center',
                                        // alignItems: 'center',
                                        // flexDirection: 'column',
                                        // height: 130,
                                        // minWidth: 110,
                                        // background: 'white',
                                        margin: (!this.state.width || this.state.width < 950) ? '0 10px' : '0 20px',
                                        width: (!this.state.width || this.state.width < 950) ? 'calc(100%-20px)' : 'auto'
                                    }}>
                                        <div style={{textAlign: 'left', position: 'absolute', bottom: 0, width: '100%', fontSize: 16,}}>
                                            Aktueller<br/>
                                            Preis
                                            <span
                                                style={{
                                                    fontSize: this.state.rows[0]['price']['price'] && this.state.rows[0]['price']['price'] > 0 ? 24 : 16,
                                                    fontWeight: 'bold',
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0
                                                }}>{this.state.rows[0]['price']['price'] && this.state.rows[0]['price']['price'] > 0 ? (this.state.rows[0]['price']['price'] / 100 + ' €') : 'Nicht Verfügbar'}</span>
                                            {!this.state.rows[0]['price'].invalidAsin && this.state.rows[0]['price'].lastUpdated &&
                                                <p style={{fontSize: 8, fontWeight: '400', textAlign: 'right', position: 'absolute', right: 0, bottom: -10}}>Aktualisiert: {moment.utc(this.state.rows[0]['price'].lastUpdated).local().fromNow()}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{padding: (!this.state.width || this.state.width < 950) ? 0 : '25px 25px 25px 0', textAlign: 'left', display: 'block', maxWidth: (1280 - 360)}}>
                                    <Box sx={{padding: '10px 0 20px', margin: 'auto'}} className={'lineChartTest ' + ((!this.state.width || this.state.width < 950) ? 'lineChartResponsive' : '') }>
                                        <Chart testWidth={this.state.width} priceHistory={this.state.rows[0]['priceHistory']}/>
                                    </Box>
                                    <div style={{padding: (!this.state.width || this.state.width < 950) ? 20 : '0 50px 0 80px'}}>
                                        <Link
                                            style={{
                                                wordWrap: 'break-word',
                                                textAlign: 'left',
                                                fontSize: 15,
                                                paddingBottom: 20,
                                                width: '100%'
                                            }}
                                            component="button"
                                            onMouseDown={(e) => {
                                                if (e.button === 1) {
                                                    window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                                }
                                            }}
                                            onClick={(e) => {
                                                window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                            }}>{this.state.rows[0]['product']['title']}</Link>

                                        <Divider/>
                                        <Grid container style={{marginTop: 20}}>
                                            <Grid item xs={12} style={{textAlign: 'right'}}>
                                                <Button variant='outlined'
                                                        onMouseDown={(e) => {
                                                            if (e.button === 1) {
                                                                window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                                            }
                                                        }}
                                                        onClick={(e) => {
                                                            window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();
                                                        }}
                                                        onMouseEnter={() => this.setState({isHovering: this.state.rows[0]['price']['id']})}
                                                        onMouseLeave={() => this.setState({isHovering: null})}
                                                        style={{
                                                            height: 34,
                                                            background: this.state.isHovering !== this.state.rows[0]['price']['id'] ? '#f87800' : 'green',
                                                            border: 0,
                                                            minWidth: 110,
                                                            marginRight: 2,
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                            paddingTop: 7,
                                                            paddingBottom: 7
                                                        }}>
                                                    <ShoppingCart sx={{fontSize: 20, color: 'white'}}/>&nbsp;<span
                                                    style={{color: 'white', fontWeight: 600}}>Kaufen</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </CardContent>
                            {(this.state && this.state.rows && this.state.rows.length > 1 && this.state.rows[1]['wishlists'] && this.state.rows[1]['wishlists'].length > 0 || (this.state.rows.length > 1 && this.state.rows[1]['alerts'] && this.state.rows[1]['alerts'].length > 0)) && <>
                                <Divider/>
                                <Grid container style={{padding: 25}}>
                                    <Grid item xs={6} style={{textAlign: 'left'}}>
                                        {this.state.rows[1]['wishlists'].length > 0 && <h3>Wunschlisten</h3>}
                                        {this.state.rows[1]['wishlists'].map((item, index) => {
                                            return (
                                                <p key={index} style={{margin: 0}}>
                                                    <Link
                                                        style={{fontSize: 16}}
                                                        component="button"
                                                        onClick={(e) => {
                                                            this.props.history.push({
                                                                pathname: '/wunschliste/' + item.id,
                                                                state: {
                                                                    data: {
                                                                        id: this.state.rows[0]['product']['id'],
                                                                        name: item.name,
                                                                        public: item.public,
                                                                    }
                                                                }
                                                            })
                                                        }}>{item.name}</Link>
                                                </p>
                                            );
                                        })}
                                    </Grid>
                                    <Grid item xs={6} style={{textAlign: 'right'}}>
                                        {this.state.rows[1]['alerts'].length > 0 && <h3>Preisalarme</h3>}
                                        {this.state.rows[1]['alerts'].map((item, index) => {
                                            return (
                                                <p key={index} style={{margin: 0}}>
                                                    <Link
                                                        style={{fontSize: 16}}
                                                        component="button"
                                                        onClick={(e) => {
                                                            // this.props.history.push('/alarme')
                                                            this.props.history.push({
                                                                pathname: '/alarme',
                                                                state: {
                                                                    data: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            })
                                                        }}>{(item.condition / 100).toFixed(2)} €</Link>
                                                </p>
                                            );
                                        })}
                                    </Grid>
                                </Grid>


                            </>}
                        </Card>
                    </>
                }













                {(this.state && this.state.rows && !this.state.productNotFound && this.state.rows.length > 0 && this.state.rows[0]['product']['ean'] !== '') &&
                    <div style={{display: 'block'}}>
                        <div style={{textAlign: 'left', minHeight: 61, padding: '0 0 0 10px'}}>
                            {this.state.rows[0]['productBefore'] &&
                                <Button style={{textAlign: 'left', maxWidth: 80}} onClick={() => {
                                    this.props.history.push('/p/' + this.state.rows[0]['productBefore']);
                                    this.setState({
                                        id: this.state.rows[0]['productBefore']
                                    }, () => {
                                        this.getData();
                                    })
                                }}>
                                    Vorheriges Produkt
                                </Button>}

                            <span style={{float: 'right'}}>
                            {this.state.rows[0]['productAfter'] &&
                                <Button style={{maxWidth: 80, textAlign: 'right'}} onClick={() => {
                                    this.props.history.push('/p/' + this.state.rows[0]['productAfter']);
                                    this.setState({
                                        id: this.state.rows[0]['productAfter']
                                    }, () => {
                                        this.getData();
                                    })
                                }}>
                                    Nächstes Produkt
                                </Button>}
                                </span>
                        </div>
                        <Card sx={{
                            // maxWidth: 750,
                            // width: 400,
                            margin: '0 auto 50px',
                            border: '2px solid rgba(211,211,211,0.4)',
                            // boxShadow: '0px 0px 15px 6px rgb(46 61 73 / 15%)',
                            boxShadow: 'unset',
                            // transition: 'all 0.3s ease'
                            // padding: '15px 25px',
                        }}>
                            <CardContent style={{
                                padding: 0,
                                display: (!this.state.width || this.state.width < 950) ? 'block' : 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                <div className={(!this.state.width || this.state.width < 950) ? 'asdasd' : ''} style={{padding: (!this.state.width || this.state.width < 950) ? 10 : '10px 0 10px 10px', minWidth: (!this.state.width || this.state.width < 950) ? 0 : 360, maxWidth: (!this.state.width || this.state.width < 950) ? '100%' : 360, width: (this.state.width && this.state.width < 950) ? 'calc(100% - 20px)' : 'auto' }}>
                                    {/*{(this.state.rows[0]['productFiles'] && this.state.rows[0]['productFiles'].filter(i => i.objectType == 'Front').length > 0) && <img*/}
                                    {/*    width="320"*/}
                                    {/*    style={{*/}
                                    {/*        height: 'auto',*/}
                                    {/*        width: (!this.state.width || this.state.width < 950) ? '100%' : 320,*/}
                                    {/*        margin: 'auto',*/}
                                    {/*        cursor: 'pointer'}}*/}
                                    {/*    src={'https://api.nicer-pricer.de/file/' + this.state.rows[0]['productFiles'].filter(i => i.objectType === 'Front')[0]['id'] + '/download/content'}*/}
                                    {/*    onMouseDown={(e) => {*/}
                                    {/*        if (e.button === 1) {*/}
                                    {/*            window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    onClick={(e) => {*/}
                                    {/*        window.open(this.state.rows[0]['price']['productLink'], '_blank').focus();*/}
                                    {/*    }}*/}
                                    {/*/>}*/}
                                    <ImageGallery items={this.state.images} />
                                </div>
                                <div style={{padding: (!this.state.width || this.state.width < 950) ? 25 : 25, textAlign: 'left', display: 'block', maxWidth: (1280 - 360)}}>
                                    <p>Titel: {this.state.rows[0]['product']['title']}</p>
                                    <p>Beschreibung: {this.state.rows[0]['product']['description']}</p>
                                </div>
                            </CardContent>
                            {(this.state && this.state.rows && this.state.rows.length > 1 && this.state.rows[1]['wishlists'] && this.state.rows[1]['wishlists'].length > 0 || this.state.rows[1]['alerts'] && this.state.rows[1]['alerts'].length > 0) && <>
                                <Divider/>
                                <Grid container style={{padding: 25}}>
                                    <Grid item xs={6} style={{textAlign: 'left'}}>
                                        {this.state.rows[1]['wishlists'].length > 0 && <h3>Wunschlisten</h3>}
                                        {this.state.rows[1]['wishlists'].map((item, index) => {
                                            return (
                                                <p key={index} style={{margin: 0}}>
                                                    <Link
                                                        style={{fontSize: 16}}
                                                        component="button"
                                                        onClick={(e) => {
                                                            this.props.history.push({
                                                                pathname: '/wunschliste/' + item.id,
                                                                state: {
                                                                    data: {
                                                                        id: this.state.rows[0]['product']['id'],
                                                                        name: item.name,
                                                                        public: item.public,
                                                                    }
                                                                }
                                                            })
                                                        }}>{item.name}</Link>
                                                </p>
                                            );
                                        })}
                                    </Grid>
                                    <Grid item xs={6} style={{textAlign: 'right'}}>
                                        {this.state.rows[1]['alerts'].length > 0 && <h3>Preisalarme</h3>}
                                        {this.state.rows[1]['alerts'].map((item, index) => {
                                            return (
                                                <p key={index} style={{margin: 0}}>
                                                    <Link
                                                        style={{fontSize: 16}}
                                                        component="button"
                                                        onClick={(e) => {
                                                            // this.props.history.push('/alarme')
                                                            this.props.history.push({
                                                                pathname: '/alarme',
                                                                state: {
                                                                    data: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            })
                                                        }}>{(item.condition / 100).toFixed(2)} €</Link>
                                                </p>
                                            );
                                        })}
                                    </Grid>
                                </Grid>


                            </>}
                        </Card>
                    </div>
                }











                {(this.state.productNotFound && (this.state.rows.length == 0 || !this.state.rows[0]['product'])) &&
                    <p>Produkt wurde nicht gefunden</p>
                }
            </Container>
        );
    }
}

export default withRouter(ProductDetail);


