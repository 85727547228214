import * as d3 from 'd3';
import classnames from 'classnames';

function getClosestDate(data,date) {
  let bestDate = -1;
  let bestDiff = -(new Date(0,0,0)).valueOf();
  let currDiff = 0;
  let i;

  for(i = 0; i < data.length; ++i){
    currDiff = Math.abs(data[i].date - date);
    if(currDiff < bestDiff){
      bestDate = i;
      bestDiff = currDiff;
    }
  }
  return bestDate;
}


function drawTooltip(config) {
  const {
    margin,
    width,
    height,
    data,
    svgRef,
    tooltipRef,
    markerClass,
    xScale,
    yScale,
  } = config;

  const svg = d3.select(svgRef.current).select('g');
  const tooltip = d3.select(tooltipRef.current);

  const focus = svg
    .append('g')
    .attr('class', 'focus')
    .style('display', 'none');

  focus
    .append('circle')
    .attr('r', 5)
    .attr('class', classnames(['line-chart__circle', markerClass]))

  //focus.style('visibility', 'hidden');

  svg
    .append('rect')
    .attr('class', 'overlay')
    .attr('width', width)
    .attr('height', height)
    .style('opacity', 0)
    .on('mouseover', () => {
      focus.style('display', null);
    })
    .on('mouseout', () => {
      console.log('out')
      focus.style('opacity', 0);
      tooltip
        .transition()
        .duration(100)
        .style('opacity', 0);
    })
    .on('mousemove', mousemove);

  function mousemove(event) {
    const bisect = d3.bisector((d) => d.label).left;
    const xPos = d3.pointer(event)[0];
    const invertedPoint = xScale.invert(xPos);
    // const x0 = bisect(data, invertedPoint);
    // const d0 = findHoverData ? findHoverData(d3.pointer(event), height, data, xScale, yScale) : data[x0];


    const x0 = getClosestDate(data, invertedPoint);
    const d0 = data[x0];


    focus.style('opacity', 1);

    focus.attr(
      'transform',
      `translate(${xScale(d0.date)},${yScale(d0.value)})`,
    );

    tooltip
      .transition()
      .duration(100)
      .style('opacity', 0.9);

    tooltip
      .html(d0.tooltipContent || d0.date)
      .style(
        'transform',
        'translate(-50%,-100%)',
      )
      .style(
        'left',
        `${xScale(d0.date) + margin.left}px`,
      )
      .style(
        'top',
        `${yScale(d0.value) + margin.top - 10}px`,
      );
  }
}

export default drawTooltip;
