import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

export default function Impressum() {

    const location = useLocation();

    useEffect(() => {
        document.title = 'Impressum';
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>

            <div style={{textAlign: 'left'}}>
                <h1>Impressum</h1>

                <div>
                    <br/>
                    <p>nicer-pricer ist ein Produkt der <a href="https://we-push-it.com">PUSH IT</a> GmbH</p>
                    <p>Die Website nicer-pricer ist Teilnehmer des Partnerprogramms von Amazon Europe S.à r.l., ein
                        Partnerwerbeprogramm, das für Websites konzipiert wurde, mittels dessen durch die
                        Platzierung von Werbeanzeigen und Links zu amazon.co.uk / Javari.co.uk / amazon.de /
                        amazon.fr / Javari.fr / amazon.it Werbekostenerstattungen verdient werden können.</p>
                    <h2>Angaben gemäß § 5 TMG</h2>
                    PUSH IT GmbH <br/>
                    Neuhauser Weg 5 <br/>
                    87439 Kempten <br/>

                    Registernummer: HRB 14130 <br/>
                    Registergericht: Amtsgericht Kempten <br/>
                    <br/>
                    <strong>Vertreten durch den Geschäftsführer: </strong>
                    Dominik Haßelkuss
                    <h2>Kontakt</h2>
                    Telefon: +49 (0) 831 – 930 62 24 0 <br/>
                    E-Mail: hello@we-push-it.com
                    <h2>Umsatzsteuer</h2>
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: <br/>
                    DE316910948
                    <h2>Streitschlichtung</h2>
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                    href="https://ec.europa.eu/consumers/odr"> https://ec.europa.eu/consumers/odr</a>.
                    Unsere E-Mail-Adresse finden Sie oben im Impressum.

                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.
                    <h3>Haftung für Inhalte</h3>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                    nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.

                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                    der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    <h3>Haftung für Links</h3>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                    Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                    verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                    Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                    erkennbar.

                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                    <h3>Urheberrecht</h3>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                    jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                    nicht kommerziellen Gebrauch gestattet.

                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                    umgehend entfernen.
                    <br/><br/>
                    Quelle: <a href="https://www.e-recht24.de/impressum-generator.html" target="_blank"
                               rel="noopener noreferrer">https://www.e-recht24.de/impressum-generator.html</a>
                </div>
                <div>
                    <h2>Amazon PartnerNet</h2>
                    Die Website ist Teilnehmer des Partnerprogramms von Amazon Europe S.à r.l., ein
                    Partnerwerbeprogramm, das für Websites konzipiert wurde, mittels dessen durch die Platzierung von
                    Werbeanzeigen und Links zu amazon.co.uk / Javari.co.uk / amazon.de / amazon.fr / Javari.fr /
                    amazon.it Werbekostenerstattungen verdient werden können. Alle Produktinformationen und Preise
                    stammen von Amazon Europe S.à r.l. und können jederzeit geändert oder gelöscht werden.


                    {/*<h2>Bildquellen</h2>*/}
                    {/*<p><a href="https://icons8.com/icon/2975/gelddose" target="_blank">Gelddose icon by Icons8</a></p>*/}
                    <p></p>
                </div>

            </div>
        </Container>
    );
}
