import * as React from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import {visuallyHidden} from '@mui/utils';
import Button from '@mui/material/Button';
import {css, useTheme} from "@emotion/react";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {Grid, Hidden, TextField} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Clear, Delete, Edit, NotificationAdd, SaveAlt, ShoppingCart} from "@mui/icons-material";
import base64 from "base-64";
import {ReactSession} from "react-client-session";
import {confirmAlert} from "react-confirm-alert";
import useFetch from "./UseFetch";
import ReactTooltip from "react-tooltip";
import {MoonLoader} from "react-spinners";
import moment from "moment";

export default function AlertsDatagrid(props) {

    const {rows, newData, hasMore, loading, setLoading, initialDataUrl, reload, notify, pauseInterval, continueInterval} = props;
    const api = useFetch();

    const [hasMoreT, setHasMoreT] = useState(true);

    const [price, setPrice] = useState(null);
    const [items, setItems] = useState(null);
    const [data, setData] = useState([]);

    const history = useHistory();


    const confirmDelete = (id) => {

        let token = ReactSession.get('token');
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diesen Alarm wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let url = process.env.REACT_APP_API_URL + '/alert' + '/' + id;
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            reload(false, 'Dein Alarm wurde erfolgreich gelöscht')
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    useEffect(() => {
        setHasMoreT(hasMore);
        if (rows !== null && rows.length !== 0) {

            rows.map((item, index) => {
                item.edit = false;
            })
            setItems(rows);
        }
    }, [])

    useEffect(() => {
        setHasMoreT(hasMore);
        console.log('UPDATE')
        if (rows !== null) {
            const tempItems = [...rows];
            setItems(tempItems);
        } else {
            setItems(null)
        }
    }, [rows])


    const handleOnClick = (item) => {
        console.log('click');
        const tempItems = [...items];

        tempItems.map((i, index) => {
            if (i.id === item.id) {
                i.edit = !i.edit;
                pauseInterval();
                setPrice((parseFloat(i.attributes.condition) / 100));
            } else {
                i.edit = false;
            }
        })
        setItems(tempItems)
    }

    const handleSave = async (item) => {
        console.log('click');
        const tempItems = [...items];

        tempItems.map((i, index) => {
            i.edit = false;
        })
        setItems(tempItems);

        const body = new FormData;


        let updatedAttributes = {
            data: {
                attributes: {
                    condition: parseInt(Math.round(parseFloat(price) * 100 * 10) / 10)
                }
            }
        }

        let url = process.env.REACT_APP_API_URL + "/alert/" + item.id;
        const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));
        if (response) {
            reload(false, 'Dein Alarm wurde erfolgreich aktualisiert');
        }
        continueInterval();

    }

    const override = css`
    min-height: 20px;
  display: inline-flex;
`;

    const handleKeypress = (e, item) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            handleSave(item);
        }
    };

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = (id) => {
        setIsHovering(id);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <React.Fragment>
            <div className="container">
                <Hidden smDown>
                    <Grid container spacing={2}
                          style={{
                              paddingTop: 10,
                              display: 'flex',
                              alignItems: 'center',
                              borderBottom: '2px solid #ddd'
                          }}>
                        <Grid item xs={12} md={2} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            Produkt
                        </Grid>
                        <Grid item xs={12} md={6} style={{textAlign: 'left', fontWeight: 'bold'}}>

                        </Grid>
                        <Grid item xs={12} md={1} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Preis*
                        </Grid>
                        <Grid item xs={12} md={3} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Wunschpreis
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
            <InfiniteScroll
                dataLength={items?.length ?? 0} //This is important field to render the next data
                next={newData}
                hasMore={hasMoreT}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        {/*<b>No more items to load</b>*/}
                    </p>
                }
                style={{overflow: 'unset'}}
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
            >
                {items && items.map((item, index) => {
                    return (<Grid container direction="row" spacing={2} key={item.id}
                                  style={{
                                      paddingTop: 10,
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderBottom: '2px solid #ddd',
                                      minHeight: '192px'
                                  }}>
                        <Grid item xs={12} md={2} style={{textAlign: 'center', fontWeight: 'bold'}}
                              className='wishlistImage'>
                            <div style={{position: 'relative'}}>
                                <a href={item.attributes.productLink} target={"_blank"}>
                                    <img height={160} style={{maxWidth: 160, maxHeight: 160, width: 'auto', height: 'auto'}} src={item.attributes.imageUrl} alt=""
                                         className="imageUrlAjax"/>
                                </a>
                                {item.attributes.imageUrl === "" &&
                                    <div data-tip data-for="loaderTooltip">
                                        <ReactTooltip id="loaderTooltip" place="top" effect="float">
                                            <span
                                                style={{fontSize: 10}}>Wir recherchieren den aktuellen Preis für Dich.<br/>Bitte habe noch etwas Geduld.</span>
                                        </ReactTooltip>
                                        <MoonLoader speedMultiplier={1} css={override} loading={true} color={'black'}
                                                    size={16}/></div>
                                }
                                {item.attributes.percentage > 0 && <p style={{
                                    position: 'absolute',
                                    height: 25,
                                    width: 25,
                                    top: 0,
                                    right: 0,
                                    fontSize: 12,
                                    background: '#F78700',
                                    padding: 10,
                                    borderRadius: 25,
                                    lineHeight: '25px',
                                }}>-{item.attributes.percentage}%</p>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            <div className="productTitleAjax" style={{minHeight: 25, marginBottom: 20}}>
                                <a style={{lineBreak: 'anywhere'}} href={item.attributes.productLink} target={'_blank'}>{item.attributes.title}</a>
                            </div>

                            <Box sx={{display: {md: 'block', xs: 'none'}}}>
                                <Button variant='outlined'
                                        href={item.attributes.productLink}
                                        target={'_blank'}
                                        onMouseEnter={() => handleMouseEnter(item.id)}
                                        onMouseLeave={() => handleMouseLeave()}
                                        style={{
                                            background: isHovering !== item.id ? '#f87800' : 'green',
                                            border: 0,
                                            minWidth: 100,
                                            marginRight: 2,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 7,
                                            paddingBottom: 7
                                        }}>
                                    <ShoppingCart sx={{fontSize: 20, color: 'white'}}/>&nbsp;<span
                                    style={{color: 'white', fontWeight: 600}}>Kaufen</span>
                                </Button>
                            </Box>

                            <div style={{paddingRight: 5}}>
                            <Box sx={{display: {md: 'none', sm: 'block'}}}>
                                <Button variant='outlined'
                                        href={item.attributes.productLink}
                                        target={'_blank'}
                                        style={{
                                            background: '#f87800',
                                            border: 0,
                                            minWidth: 100,
                                            marginRight: 2,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 7,
                                            paddingBottom: 7
                                        }}>
                                    <ShoppingCart sx={{fontSize: 20, color: 'white'}}/>&nbsp;<span
                                    style={{color: 'white', fontWeight: 600}}>Kaufen</span>
                                </Button>
                                <div style={{textAlign: 'right', float: 'right', paddingTop: 10}}>
                                    {!item.attributes.invalidAsin && new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.price ?? 0) / 100)}
                                    {!item.attributes.invalidAsin && item.attributes.lastUpdated &&
                                        <p style={{fontSize: 8, fontWeight: '400', textAlign: 'right'}}>Aktualisiert: {moment.utc(item.attributes.lastUpdated).local().fromNow()}</p>
                                    }
                                    {item.attributes.invalidAsin === 1 && <p>Nicht verfügbar</p>}
                                </div>
                            </Box>
                            </div>
                            <div style={{height: 10}}/>

                            <Box sx={{display: {md: 'none', sm: 'block'}}} style={{marginTop: 10}}>
                                {!item.edit && <Button variant='outlined'
                                                       onClick={() => confirmDelete(item.id)}
                                                       style={{
                                                           background: '#d9534f',
                                                           border: 0,
                                                           minWidth: 40,
                                                           marginRight: 2,
                                                           marginBottom: 10,
                                                           paddingLeft: 0,
                                                           paddingRight: 0,
                                                           paddingTop: 7,
                                                           paddingBottom: 7
                                                       }}>
                                    <Delete sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}

                                <div style={{textAlign: 'right', float: 'right'}}>
                                    {item.edit &&
                                        <TextField variant="outlined"
                                                   autoFocus
                                                   label={'Preis'}
                                                   value={price ? price : ''}
                                                   style={{height: 34, width: 100, marginLeft: 5, marginRight: 10}}
                                                   InputLabelProps={{
                                                       style: {
                                                           height: 34,
                                                           //...(!focused && { top: `${labelOffset}px` }),
                                                       },
                                                   }}
                                                   inputProps={{
                                                       style: {
                                                           height: 34,
                                                           padding: '0 14px',
                                                       },
                                                   }}
                                                   onChange={(e) => {
                                                       if (e.target.value == '') {
                                                           setPrice('')
                                                       } else {
                                                           let amount = e.target.value.replace(',', '.');

                                                           const regex = /^([0-9]{1,6}([.,][0-9]{0,2})?|[,.][0-9])$/gm;

                                                           let m;

                                                           while ((m = regex.exec(amount)) !== null) {
                                                               if (m.index === regex.lastIndex) {
                                                                   regex.lastIndex++;
                                                               }

                                                               if (!e.target.value || e.target.value == '' || m[0]) {
                                                                   setPrice(m[0]);
                                                               }
                                                           }
                                                       }
                                                   }}
                                                   onKeyPress={(e) => handleKeypress(e, item, item.attributes.condition > 0)}/>}
                                    {item.edit && <Button variant='outlined' onClick={() => {
                                        handleSave(item, item.attributes.condition > 0);
                                    }} style={{
                                        border: 0,
                                        background: '#d9534f',
                                        minWidth: 40,
                                        marginRight: 2,
                                        marginBottom: 10,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingTop: 7,
                                        paddingBottom: 7
                                    }}><SaveAlt sx={{fontSize: 20, color: 'white'}}/></Button>}

                                    {!item.edit && item.attributes.condition > 0 &&
                                        <Button variant='outlined'
                                                onClick={() => {
                                                    handleOnClick(item);
                                                }}
                                                style={{
                                                    background: '#d9534f',
                                                    border: 0,
                                                    minWidth: 40,
                                                    marginRight: 2,
                                                    marginBottom: 10,
                                                    paddingLeft: 14,
                                                    paddingRight: 14,
                                                    paddingTop: 2,
                                                    paddingBottom: 3
                                                }}>
                                    <span style={{
                                        paddingTop: 4.5,
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}>{new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.condition / 100))}</span>&nbsp;
                                            <Edit sx={{fontSize: 20, color: 'white'}}/>
                                        </Button>}

                                    {!item.edit && (item.attributes.condition < 1 /* && (priceID !== item.id || priceID === null) */) &&
                                        <Button variant='outlined'
                                                onClick={() => {
                                                    handleOnClick(item);
                                                }}
                                                style={{
                                                    background: '#f87800',
                                                    border: 0,
                                                    minWidth: 40,
                                                    marginBottom: 10,
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    paddingTop: 7,
                                                    paddingBottom: 7
                                                }}>
                                            <NotificationAdd sx={{fontSize: 20, color: 'white'}}/>
                                        </Button>}
                                    {!item.edit && item.attributes.condition === undefined &&
                                        <MoonLoader speedMultiplier={1} css={override2} loading={true} color={'black'}
                                                    size={16}/>}
                                </div>
                            </Box>
                        </Grid>


                        <Hidden smDown>
                            <Grid item xs={12} md={2} style={{textAlign: 'right', fontWeight: 'bold', float: 'left'}}>
                                <div style={{textAlign: 'right', float: 'right', paddingTop: 10}}>
                                    {!item.attributes.invalidAsin && new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.price ?? 0) / 100)}
                                    {!item.attributes.invalidAsin && item.attributes.lastUpdated &&
                                        <p style={{fontSize: 8, fontWeight: '400', textAlign: 'right'}}>Aktualisiert: {moment.utc(item.attributes.lastUpdated).local().fromNow()}</p>
                                    }
                                    {item.attributes.invalidAsin === 1 && <p>Nicht verfügbar</p>}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} style={{textAlign: 'right', fontWeight: 'bold', float: 'left'}}>
                                {item.edit &&
                                    <TextField variant="outlined"
                                               autoFocus
                                               label={'Preis'}
                                               value={price ? price : ''}
                                               style={{height: 34, width: 120, marginLeft: 5, marginRight: 5}}
                                               InputLabelProps={{
                                                   style: {
                                                       height: 34,
                                                       //...(!focused && { top: `${labelOffset}px` }),
                                                   },
                                               }}
                                               inputProps={{
                                                   style: {
                                                       height: 34,
                                                       padding: '0 14px',
                                                   },
                                               }}
                                               onChange={(e) => {
                                                   if (e.target.value == '') {
                                                       setPrice('')
                                                   } else {
                                                       let amount = e.target.value.replace(',', '.');

                                                       const regex = /^([0-9]{1,6}([.,][0-9]{0,2})?|[,.][0-9])$/gm;

                                                       let m;

                                                       while ((m = regex.exec(amount)) !== null) {
                                                           if (m.index === regex.lastIndex) {
                                                               regex.lastIndex++;
                                                           }

                                                           if (!e.target.value || e.target.value == '' || m[0]) {
                                                               setPrice(m[0]);
                                                           }
                                                       }
                                                   }
                                               }}
                                               onKeyPress={(e) => handleKeypress(e, item, item.attributes.condition > 0)}/>}
                                {item.edit && <Button variant='outlined' onClick={() => {
                                    handleSave(item, item.attributes.condition > 0);
                                }} style={{
                                    border: 0,
                                    background: '#d9534f',
                                    minWidth: 40,
                                    marginRight: 2,
                                    marginBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingTop: 7,
                                    paddingBottom: 7
                                }}><SaveAlt sx={{fontSize: 20, color: 'white'}}/></Button>}


                                {!item.edit && item.attributes.condition > 0 && <Button variant='outlined'
                                                                                        onClick={() => {
                                                                                            handleOnClick(item);
                                                                                        }}
                                                                                        style={{
                                                                                            background: '#d9534f',
                                                                                            border: 0,
                                                                                            minWidth: 40,
                                                                                            marginRight: 2,
                                                                                            marginBottom: 10,
                                                                                            paddingLeft: 14,
                                                                                            paddingRight: 14,
                                                                                            paddingTop: 2,
                                                                                            paddingBottom: 3
                                                                                        }}>
                                    <span style={{
                                        paddingTop: 4.5,
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}>{new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    }).format((item.attributes.condition / 100))}</span>&nbsp;
                                    <Edit sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}


                                {!item.edit && (item.attributes.condition != undefined && item.attributes.condition < 1 /* && priceID !== item.id */) &&
                                    <Button variant='outlined'
                                            onClick={() => {
                                                handleOnClick(item);
                                            }}
                                            style={{
                                                background: '#f87800',
                                                border: 0,
                                                minWidth: 40,
                                                marginRight: 20,
                                                marginBottom: 10,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingTop: 7,
                                                paddingBottom: 7
                                            }}>
                                        <NotificationAdd sx={{fontSize: 20, color: 'white'}}/>
                                    </Button>}
                                {!item.edit && item.attributes.condition == undefined &&
                                    <MoonLoader speedMultiplier={1} css={override2} loading={true} color={'black'}
                                                size={16}/>}

                                {!item.edit && <Button variant='outlined'
                                                       onClick={() => confirmDelete(item.id)}
                                                       style={{
                                                           background: '#d9534f',
                                                           border: 0,
                                                           minWidth: 40,
                                                           marginRight: 2,
                                                           marginBottom: 10,
                                                           paddingLeft: 0,
                                                           paddingRight: 0,
                                                           paddingTop: 7,
                                                           paddingBottom: 7
                                                       }}>
                                    <Delete sx={{fontSize: 20, color: 'white'}}/>
                                </Button>}
                            </Grid>
                        </Hidden>
                    </Grid>)
                })}
            </InfiniteScroll>
        </React.Fragment>
    );
}
