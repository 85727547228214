import React, {Component, useEffect, useState} from 'react';

import Container from '@material-ui/core/Container';
import BaseBreadcrumbs from "./BaseBreadcrumbs";
import ApiDataComponent from "./core/ApiDataComponent";
import WunschlistenDatagrid from "./WunschlistenDatagrid";
import {withRouter} from "react-router-dom";
import WunschlistenItemDatagrid from "./WunschlistenItemDatagrid";
import base64 from "base-64";
import Button from "@mui/material/Button";
import useCollapse from "react-collapsed";
import {TextField} from "@material-ui/core";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import {Cancel} from "@mui/icons-material";
import useFetch from "./UseFetch";
import ReactGA from "react-ga4";

function Collapsible(props) {

    useEffect(() => {
        // if (ReactSession.get('credentials') === undefined) {
        //     history.push('/login');
        // }
    }, [])

    const {isExpanded, reload, notify, onUpdate, urlInit} = props;


    const [url, setUrl] = useState(urlInit ?? null);
    const {getCollapseProps} = useCollapse({isExpanded});

    useEffect(() => {
        onUpdate('url', url);
    }, [url])

    useEffect(() => {
        setUrl(urlInit);
    }, [urlInit])

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            props.createProduct();
        }
    };

    return (
        <div className="collapsible" style={{minHeight: 20}}>
            <div {...getCollapseProps()} style={{display: isExpanded ? 'block' : 'none'}}>
                <div className="content">
                    <TextField variant="outlined"
                               label={'Url'}
                               value={url}
                               placeholder={'https://www.amazon.de/dp/B07HGG3QNT'}
                               onChange={(e) => setUrl(e.target.value)}
                               onKeyPress={handleKeypress}
                               fullWidth
                               margin="dense"/>
                </div>
            </div>
        </div>
    );
}

class WunschlistenItem extends Component {

    api = undefined
    mount = false
    constructor(props) {
        super(props);

            let items = '/items';
            let initialDataUrl = props.initialDataUrl;
            this.mount = true;
            this.reload = this.reload.bind(this);
            this.newData = this.newData.bind(this);
            this.setLoading = this.setLoading.bind(this);
            this.pauseInterval = this.pauseInterval.bind(this);
            this.continueInterval = this.continueInterval.bind(this);
            this.api = useFetch();

            if (ReactSession.get('token') === undefined) {
                items = '/public';
                initialDataUrl = process.env.REACT_APP_API_URL +  '/wishlistitems/';
            }

            this.state = {
                initialDataUrl: initialDataUrl + props.match.params.id + items + '?include=product,price,alert&page%5Bsize%5D=10&page%5Bnumber%5D=1',
                item: {
                    id: props.match.params.id,
                    name: props.history?.location?.state?.data.name,
                    public: props.history?.location?.state?.data.public,
                },
                items,
                pageNumber: 1,
                hasMore: true,
                loading: false,
                initialLoading: false,
                rows: null,
                isExpanded: false,
                wishlist: props.match.params.id,
                url: '',
                dataId: props.history.location?.state?.data.id ?? null
            };
            if (props.history.location?.state?.data.name) document.title = 'Wunschliste: ' + props.history.location?.state?.data.name;



    }

    componentDidMount() {
        if (this.mount) {
            this.getData();
            if (this.state.data) {
                this.setState({isExpanded: true})
            }
        }
        ReactGA.send('https://nicer-pricer.de' + this.props.location.pathname + this.props.location.search);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.mount && prevProps.data !== this.props.data && this.props.data !== '') {

            let temp = this.props.data.match(/\bhttps?:\/\/\S+/gi);
            if (temp) temp = temp[0];
            this.setState({
                isExpanded: true,
                url: temp
            }, () => {

            })
        }
    }

    componentWillUnmount() {
        if (this.state && this.state.interval) {
            clearInterval(this.state.interval);
        }
        this.mount = false;
    }

    getData = async (getMore = false) => {
        let credentials = ReactSession.get('credentials');
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(credentials));
        headers.append('Content-Type', 'application/vnd.api+json');

        let rows = []
        let moreData = true;
        let total = 0;
        let timer = false;


        if (this.state.search == undefined || this.state.search === '') {

            // if (!this.state.newData) {
            //     if (this.state.rows !== null) rows = [...this.state.rows];
            //     else rows = [];
            //     this.setState({
            //         newData: false
            //     })
            // }

            for (let i = 1; i < this.state.pageNumber + 1; i++) {
                let url = this.state.initialDataUrl + this.props.match.params.id + this.state.items + '?include=product,price,alert&page%5Bsize%5D=10&page%5Bnumber%5D=' + i;

                if (this.state.dataId) {
                    url = this.state.initialDataUrl + this.props.match.params.id + this.state.items + '?include=product,price,alert&filter%5BproductId%5D=' + this.state.dataId;
                }
                let {response, data} = await this.api(url);


                if (!response || response.status == 401 || response.status == 403) {

                    if (response.status == 403) {
                        this.notify("Diese Wunschliste ist nicht öffentlich", true);
                        this.props.history.push('/');
                    } else {
                        this.props.sessionGotUpdate(true, true);
                        ReactSession.set('token', undefined);
                        ReactSession.set('refreshToken', undefined);
                        this.props.history.push('/login');
                    }
                    return;
                }
                this.setState({ initialLoading: true });

                if (response && data && data.data) {
                    data.data.map((item, index) => {
                        //item.edit = false;

                        item.attributes.title = data.included.filter((i => i.type == 'products' && i.id == item.attributes.product_id))[0].attributes.title ?? null;
                        item.attributes.price = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.price;
                        item.attributes.percentage = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.percentage;
                        item.attributes.invalidAsin = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.invalidAsin;
                        item.attributes.lastUpdated = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.lastUpdated;
                        item.attributes.priceId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].id;
                        item.attributes.condition = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].attributes.condition : 0;
                        item.attributes.alertId = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].id : null;
                        item.attributes.productLink = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.productLink;
                        item.attributes.imageUrl = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.imageUrl;
                        item.attributes.resellerId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.resellerId;


                        if (item.attributes.title === '' || item.attributes.price == null) {
                            timer = true;
                        }

                        if (rows.filter(i => i.id === item.id).length === 0) {
                            rows.push(item);
                        } else {
                            let edit = rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit;
                            rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                            rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit = edit;                        }
                    })


                    data.data.map((item, index) => {
                        if (rows.filter(i => i.id === item.id).length === 0) {
                            rows[index].delete();
                        }
                    })

                    total = data.meta.pagination.total;
                    if (data.meta.pagination.total === rows.length) {
                        moreData = false;
                    }
                }
            }
        } else {
            let url = this.state.initialDataUrl + this.props.match.params.id + this.state.items + '?include=product,price,alert&filter%5Btitle%5D%5Blike%5D=' + this.state.search + '&page%5Bsize%5D=20';
            let {response, data} = await this.api(url);

            if (!response || response.status == 401) {
                this.props.sessionGotUpdate(true, true);
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                this.props.history.push('/login');
                return;
            }

            if (response && data) {
                data.data.map((item, index) => {
                    //item.edit = false;

                    item.attributes.title = data.included.filter((i => i.type == 'products' && i.id == item.attributes.product_id))[0].attributes.title ?? null;
                    item.attributes.price = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.price;
                    item.attributes.percentage = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.percentage;
                    item.attributes.invalidAsin = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.invalidAsin;
                    item.attributes.lastUpdated = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.lastUpdated;
                    item.attributes.priceId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].id;
                    item.attributes.condition = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].attributes.condition : 0;
                    item.attributes.alertId = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].id : null;
                    item.attributes.productLink = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.productLink;
                    item.attributes.resellerId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.resellerId;
                    if (item.attributes.title === '' || item.attributes.price == null) {
                        timer = true;
                    }

                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        let edit = rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit;
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit = edit;
                    }
                })

                data.data.map((item, index) => {
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows[index].delete();
                    }
                })


                total = data.meta.pagination.total;
                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }
            }
        }

        if (timer && !this.state.interval) {
            const interval = setInterval(() => {
                this.getData();
            }, 1000);
            this.setState({interval});
        } else if (!timer && this.state.interval) {
            clearInterval(this.state.interval);
            this.setState({interval: undefined});
        }

        this.setState({
            rows,
            hasMore: moreData,
            total: total,
            loading: true
        }, () => {

        })
    }

    notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    reload(newData = false, message) {
        this.setState({
            isExpanded: false,
            newData: newData,
        }, async () => {
            await this.getData(newData);
            if (message) {
                this.notify(message);
            }
        })
    }

    setLoading() {
        this.setState({
            loading: false
        })
    }

    newData() {
        if (this.state.hasMore) {
            let temp = ++this.state.pageNumber;
            this.setState({
                pageNumber: temp,
            }, () => {
                this.getData(this.state.hasMore);
            })
        } else {
            this.getData()
        }
    }

    createProduct = async () => {

        let updatedAttributes = {
            data: {
                attributes: {
                    reseller: "amazon",
                    resellerId: "1234",
                    wishlistId: this.state.wishlist,
                    url: this.state.url
                },
                relationships: {}
            }
        }

        let url = process.env.REACT_APP_API_URL + "/proposals/externalProduct";
        const {response, data} = await this.api(url, 'POST', JSON.stringify(updatedAttributes));
        if (response && data) {
            if (data.errors && data.errors[0] && response.status != '404')
            {
                this.notify("Fehlerhafte URL", true);
            } else if (response.status == '404') {
                this.notify("Produkt existiert bereits", true);
                this.setState({url: ''})
            } else {
                this.reload(false, 'Dein Produkt wurde erfolgreich angelegt');
                this.setState({url: ''})
            }


        }
    }

    onUpdate = (type, value) => {
        this.setState({
            [type]: value
        })
    }


    pauseInterval = () => {
        console.log('pause')
        clearInterval(this.state.interval);
        this.setState({interval: undefined});
    }

    continueInterval = () => {
        console.log('continue')
        const interval = setInterval(() => {
            this.getData();
        }, 5000);
        this.setState({interval});
    }

    render() {
        return (
            <Container maxWidth="lg">
                {(this.mount && this.state.initialLoading) &&
                <React.Fragment>
                <BaseBreadcrumbs routes={[{value: '', label: 'Home'}, {value: 'wunschlisten', label: 'Wunschlisten'}, {
                    value: 'name',
                    label: this.state.item ? this.state.item.name ? this.state.item.name : this.state.wishlist ?? '' : ''
                }]}/>
                    {this.state.dataId && <div style={{width: '100%', marginBottom: 5}}>
                        <Button variant={'outlined'} onClick={() => {
                            this.setState({
                                dataId: null
                            }, () => {
                                this.getData();
                            })
                            window.history.replaceState({}, document.title)

                        }}>Filter zurücksetzen</Button>
                    </div>}
                    {ReactSession.get('token') ? <>
                            {!this.state.isExpanded &&
                                <Button variant={'outlined'} onClick={() => this.setState({isExpanded: !this.state.isExpanded})}>Produkt
                                    anlegen</Button>}
                            {this.state.isExpanded &&
                                <React.Fragment>
                                    <Button variant={'contained'} color={'success'} disabled={this.state.url ? this.state.url.length === 0 : true}
                                            onClick={() => this.createProduct()}>Produkt anlegen</Button>
                                    <Button variant={'contained'} color={'error'} style={{marginLeft: 5}}
                                            onClick={() => this.setState({isExpanded: false, url: ''})}><Cancel
                                        style={{opacity: 0.8}}/></Button>
                                </React.Fragment>}
                        </> : null}
                <Collapsible createProduct={this.createProduct} notify={this.notify} isExpanded={this.state.isExpanded} reload={this.reload}
                             onUpdate={this.onUpdate} urlInit={this.state.url}/>
                <WunschlistenItemDatagrid pauseInterval={this.pauseInterval} continueInterval={this.continueInterval} notify={this.notify} rows={this.state.rows} newData={this.newData}
                                          hasMore={this.state.hasMore} loading={this.state.loading}
                                          setLoading={this.setLoading} initialDataUrl={this.state.initialDataUrl}
                                          reload={this.reload}/>
                </React.Fragment>}

            </Container>
        );
    }
}

export default withRouter(WunschlistenItem);


