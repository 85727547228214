import React, {useEffect, useState} from 'react';
import './App.css';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import MenuAppBar from "./MenuAppBar";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
} from "react-router-dom";
import Home from "./components/Home";
import Wunschlisten from "./components/Wunschlisten";
import Alerts from "./components/Alerts";
import WunschlistenItem from "./components/WunschlistenItem";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Privacy from "./components/Privacy";
import Impressum from "./components/Impressum";
import {ReactSession} from "react-client-session";
import ScrollToTop from "./components/ScrollToTop";
import PasswortReset from "./components/PasswortReset";
import {toast, ToastContainer} from "react-toastify";
import Verify from "./components/Verify";
import PasswortVergessen from "./components/PasswortVergessen";
import moment from 'moment';
import 'moment/locale/de';
import Blog from "./components/Blog";
import ReactGA from 'react-ga4';
import ProductDetail from "./components/ProductDetail";
import Faq from "./components/Faq";
import KontoLoeschen from "./components/KontoLoeschen";


const theme = createTheme({
    palette: {
        primary: {
            main: '#101010',
        },
        secondary: {
            main: '#f87800',
        },
    },
});

moment.updateLocale('de', {
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: function (number, withoutSuffix, key, isFuture) {
            return number + ' Sekunden';
        },
        m: "einer Minute",
        mm: function (number, withoutSuffix, key, isFuture) {
            return number + ' Minuten';
        },
        h: "einer Stunde",
        hh: "%d Stunden",
        d: "einem Tag",
        dd: "%d Tagen",
        M: "einem Monat",
        MM: "%d Monaten",
        y: "einem Jahr",
        yy: "%d jahren"
    }
});


function App() {

    const [update, setUpdate] = useState(false);
    const [token, setToken] = useState();
    const [logout, setLogout] = useState(false);
    useEffect(() => {
        ReactGA.initialize('G-3592W8P8LC');

        return () => {
            console.log('unmount');
            //unregister();
        }
    }, [])

    const sessionGotUpdate = (logout = false, tokenTimeout = false) => {
        console.log('session update');

        let log = false;
        if (logout) {
            log = true;
        }

        setUpdate(true);
        setLogout(log);
        setToken(ReactSession.get('token'));

        if (log) {
            console.log('UPDATE');
            console.log('componentDidUpdate')
            if (tokenTimeout) {
                notify('Deine Sitzung ist abgelaufen', true);
            } else {
                notify('Du wurdest erfolgreich abgemeldet');
            }
            setLogout(false);
        }
    }

    useEffect(() => {

    }, [logout])

    const notify = (message, error) => {
        console.log('NOTIFY')

        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 80}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 80}
            });
        }

    }


    return (
        <Router basename={process.env.REACT_APP_BASE_URL}>
            <ToastContainer style={{margin: '0 auto', width: 'auto'}}/>
            <ThemeProvider theme={theme}>
                <div className="App">

                    <header className="spk-header">
                        <MenuAppBar token={token} logout={logout} update={update} sessionGotUpdate={sessionGotUpdate}/>
                    </header>

                    {/* A <Switch> looks through its children <Route>s and
                   renders the first one that matches the current URL. */}
                    <ScrollToTop/>

                    <Switch>
                        <Route path="/login">
                            <Login sessionGotUpdate={sessionGotUpdate}/>
                        </Route>

                        <Route path="/registration">
                            <Registration/>
                        </Route>

                        <Route path="/verify/:token">
                            <Verify/>
                        </Route>

                        <Route path="/datenschutzerklärung">
                            <Privacy/>
                        </Route>

                        <Route path="/impressum">
                            <Impressum/>
                        </Route>

                        <Route path="/faq">
                            <Faq sessionGotUpdate={sessionGotUpdate} />
                        </Route>

                        <Route path="/wunschlisten/:type">
                            <Wunschlisten sessionGotUpdate={sessionGotUpdate}
                                          initialDataUrl={process.env.REACT_APP_API_URL + '/wishlists'}/>
                        </Route>

                        <Route path="/wunschlisten">
                            <Wunschlisten sessionGotUpdate={sessionGotUpdate}
                                          initialDataUrl={process.env.REACT_APP_API_URL + '/wishlists'}/>
                        </Route>

                        <Route path="/wunschliste/:id">
                            <WunschlistenItem sessionGotUpdate={sessionGotUpdate}
                                              initialDataUrl={process.env.REACT_APP_API_URL + '/wishlist/'}/>
                        </Route>

                        <Route path="/p/:id/:seo">
                            <ProductDetail sessionGotUpdate={sessionGotUpdate}
                                           initialDataUrl={process.env.REACT_APP_API_URL + '/'}/>
                        </Route>

                        <Route path="/p/:id">
                            <ProductDetail sessionGotUpdate={sessionGotUpdate}
                                         initialDataUrl={process.env.REACT_APP_API_URL + '/'} />
                        </Route>

                        <Route path="/alarme/:id/:type">
                            <Alerts sessionGotUpdate={sessionGotUpdate}
                                    initialDataUrl={process.env.REACT_APP_API_URL + '/alerts'}/>
                        </Route>

                        <Route path="/alarme/:type">
                            <Alerts sessionGotUpdate={sessionGotUpdate}
                                    initialDataUrl={process.env.REACT_APP_API_URL + '/alerts'}/>
                        </Route>

                        <Route path="/alarme">
                            <Alerts sessionGotUpdate={sessionGotUpdate}
                                    initialDataUrl={process.env.REACT_APP_API_URL + '/alerts'}/>
                        </Route>

                        <Route path="/passwort-reset/:token">
                            <PasswortReset sessionGotUpdate={sessionGotUpdate}/>
                        </Route>

                        <Route path="/passwort-reset">
                            <PasswortReset sessionGotUpdate={sessionGotUpdate}/>
                        </Route>

                        <Route path="/konto-loeschen">
                            <KontoLoeschen sessionGotUpdate={sessionGotUpdate}/>
                        </Route>

                        <Route path="/passwort-vergessen">
                            <PasswortVergessen/>
                        </Route>

                        <Route path="/blog/:id">
                            <Blog sessionGotUpdate={sessionGotUpdate}/>
                        </Route>

                        {/* Main entry page */}
                        <Route path="/">
                            <Home sessionGotUpdate={sessionGotUpdate}/>
                        </Route>
                    </Switch>

                    <footer style={{paddingBottom: 10}}>
                        <p style={{margin: 5}}>Werbung - Die Website nicer-pricer.de ist Teilnehmer des Partnerprogramms
                            von Amazon Europe S.à r.l.. Alle Verlinkungen sind Provisionslinks.</p>
                        <p style={{margin: 5}}>© PUSH IT GmbH 2022. ALLE RECHTE VORBEHALTEN.</p>
                        <p style={{marginTop: 3}}>
                            <Link key={'impressum'} underline="hover" color="inherit"
                                  to={'/impressum'}>
                                Impressum
                            </Link>&nbsp;|&nbsp;
                            <Link key={'datenschutzerklärung'} underline="hover" color="inherit"
                                  to={'/datenschutzerklärung'}>
                                Datenschutzerklärung
                            </Link>
                        </p>
                    </footer>
                </div>

            </ThemeProvider>
        </Router>
    );
}

export default App
