import React from 'react';

import Container from '@material-ui/core/Container';
import {Grid, Hidden} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import moment from 'moment'

class Blog extends React.Component {

    api = undefined
    content = undefined

    constructor(props) {
        super(props);
        console.log(props);
        document.title = 'Blog - ' + props.match.params.id;
        if (props.match.params.id == 1) {
            let content = [];
            content.push(<h1>Urlaub</h1>);
            content.push(<h4>nicer-pricer Redaktion - 10.11.2019</h4>);
            content.push(<img src={require('./../images/urlaub.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<p>Dein Urlaub für nächstes Jahr ist schon geplant, aber dir fehlt noch das gewisse extra? Wir haben
                uns für dich umgesehen und die coolsten Urlaubsgadgets gesucht.</p>);
            subcontent.push(<p>Probleme beim Check-In im Flughafen will niemand, besonders nicht wegen eines zu schweren
                Koffers. Dass ich nicht nur super nervig, sondern wird auch immer teuer. Damit dir das nicht
                passiert solltest du
                am besten noch zuhause oder im Hotel deinen Koffer wiegen. Am einfachsten funktioniert das mit
                einer dieser handlichen Kofferwaage.</p>);
            subcontent.push(<a href="https://www.amazon.de/ETEKCITY-Kofferwaage-Gep%C3%A4ckwaage-H%C3%A4ngewaage-Temperaturanzeige/dp/B00QV1F27I?tag=moaas09-21">Kofferwaage</a>);
            subcontent.push(<p>Dieses Modell passt in jeden Koffer und ist mit 50kg auch für sehr schwere Koffer geeignet.</p>);
            subcontent.push(<p>Hier findest du alle unsere Top-Produkte</p>);

            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        } else if (props.match.params.id == 2) {
            let content = [];
            content.push(<h1>Technik</h1>);
            content.push(<h4>nicer-pricer Redaktion - 15.10.2019</h4>);
            content.push(<img src={require('./../images/technik.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<h3>Dein alter Computer benötigt eine Ewigkeit zum Starten? Und auch dann kannst Du nicht einmal schnell surfen oder Instagram checken?</h3>);
            subcontent.push(<p>Um wieder schnell arbeiten zu können haben wir uns etwas umgesehen und für Dich einige super schnelle Geräte gesucht. Eines unserer Topmodelle war dabei das Microsoft Surface 6 pro was mit seiner
                insgesamt tollen performance aber auch einer langen Akkulaufzeit sowie einem Top-Display punkten konnte.</p>);
            subcontent.push(<a href="https://www.amazon.de/Microsoft-Surface-Tablet-Intel-Platin/dp/B07HHLFMB8?tag=moaas09-21">Microsoft Surface Pro 6, 31,25 cm</a>);
            subcontent.push(<p>Falls du gerade auf der suche nach einem neuen Computer bist und dabei die Performance eines Notebooks und handlichkeit eines Tablets in einem Gerät verbinden möchtest, solltest du dir das Surface auf jedenfall ansehen.</p>);

            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        } else if (props.match.params.id == 3) {
            let content = [];
            content.push(<h1>Bücher und Filme</h1>);
            content.push(<h4>nicer-pricer Redaktion - 19.10.2019</h4>);
            content.push(<img src={require('./../images/film.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<p>Wenn die Tage wieder kurzer und dunkler werden ist die perfekte Zeit für einen Filmabend zusammen mit den liebsten.</p>);
            subcontent.push(<p>Wem die auswahl hier schwer fällt, für den haben wir hier die Top-3 unserer Filme</p>);
            subcontent.push(<a href="https://www.amazon.de/K%C3%B6nig-L%C3%B6wen-Neuverfilmung-2019/dp/B07TS3RDZS?tag=moaas09-21">Der König der Löwen – Neuverfilmung 2019</a>);
            subcontent.push(<br />);

            subcontent.push(<a href="https://www.amazon.de/John-Wick-Kapitel-3-Blu-ray/dp/B07R76N8MN?tag=moaas09-21">John Wick: Kapitel 3</a>);
            subcontent.push(<br />);

            subcontent.push(<a href="https://www.amazon.de/Spider-Man-Home-Blu-ray-Tom-Holland/dp/B07TVKW84R?tag=moaas09-21">Spider-Man: Far From Home (Blu-ray)</a>);

            subcontent.push(<br />);
            subcontent.push(<br />);

            subcontent.push(<p>Wer sich lieber in eine Decke gekuschelt ans Fenster setzen und lesen möchten wird sicher auch bei einem unserer Top-3 Bücher das richtige finden</p>);
            subcontent.push(<a href="https://www.amazon.de/Das-Geschenk-Psychothriller-Limitierte-Sonderausgabe/dp/3426281546?tag=moaas09-21">Das Geschenk: Psychothriller (Limitierte Sonderausgabe)</a>);
            subcontent.push(<br />);
            subcontent.push(<a href="https://www.amazon.de/Ostfriesenh%C3%B6lle-Kriminalroman-Kathrin-Klaasen-ermittelt/dp/3596299284?tag=moaas09-21">Ostfriesenhölle: Kriminalroman (Ann Kathrin Klaasen ermittelt)</a>);
            subcontent.push(<br />);
            subcontent.push(<a href="https://www.amazon.de/Fremdbestimmt-120-Jahre-L%C3%BCgen-T%C3%A4uschung/dp/3982126509?tag=moaas09-21">Fremdbestimmt: 120 Jahre Lügen und Täuschung</a>);
            subcontent.push(<br />);
            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        } else if (props.match.params.id == 4) {
            let content = [];
            content.push(<h1>Feiertage</h1>);
            content.push(<h4>nicer-pricer Redaktion - 19.10.2019</h4>);
            content.push(<img src={require('./../images/feiertage.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<p>Die ruhige und besinnliche Zeit kommt näher. Bald eröffnen die ersten Wintermärkte und es werden die ersten Firmenfeiern veranstaltet.</p>);
            subcontent.push(<p>Gerade in dieser Zeit sollten wir den Menschen, die uns besonders wichtig sind, das zeigen.</p>);
            subcontent.push(<a href="https://www.amazon.de/AScreativ-Dekoflasche-Beleuchtung-Leuchtflasche-Flaschenlicht/dp/B07ZC9H8PN?tag=moaas09-21">AScreativ Dekoflasche mit Beleuchtung - Familie Zuhause LED Leuchte Leuchtflasche Bottlelight Lichterkette Geschenk Flaschenlicht</a>);
            subcontent.push(<p>Ein tolles Geschenk und auch besonderer hingucker ist diese handgemachte Flaschenlicht.</p>);
            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        } else if (props.match.params.id == 5) {
            let content = [];
            content.push(<h1>Zuhause</h1>);
            content.push(<h4>nicer-pricer Redaktion - 20.09.2019</h4>);
            content.push(<img src={require('./../images/kaffee.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<p>Ein guter Morgen fängt mit einer guten Tasse kaffe an...</p>);
            subcontent.push(<p>Um morgens gleich fit zu werden gibt es nichts besseres als eine gute Tasse Kaffee. Zumindest nicht was so gut schmeckt. Dabei ist nichts praktischer als ein Kaffeevollautomat. Knopf drücken. Fertig!</p>);
            subcontent.push(<a href="https://www.amazon.de/DeLonghi-ECAM-22-110-B-Kaffeevollautomat-Milchaufsch%C3%A4umd%C3%BCse/dp/B00400OMU0?tag=moaas09-21">DeLonghi-ECAM-22-110-B-Kaffeevollautomat</a>);
            subcontent.push(<p>Bereit für deinen perfekten morgen?</p>);

            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        } else if (props.match.params.id == 6) {
            let content = [];
            content.push(<h1>Freizeit</h1>);
            content.push(<h4>nicer-pricer Redaktion - 19.10.2019</h4>);
            content.push(<img src={require('./../images/freizeit.jpg')} alt="" style={{width: '60%'}}/>);

            let subcontent = [];
            subcontent.push(<p>Aktiv und immer sportlich unterwegs. Oder lieber ruhig und die Seele baumeln lassen</p>);
            subcontent.push(<p>Für jeden sieht die perfekte Freizeit anders aus. Aber eines sollte sie immer sein. Sicher!</p>);
            subcontent.push(<a href="https://www.amazon.de/toptrek-Fahrradhelm-EPS-K%C3%B6rper-PC-Schale-integrierte/dp/B07WP4265V?tag=moaas09-21">Fahrradhelm</a>);
            subcontent.push(<p>Immer mehr Menschen sind mit dem Fahrrad unterwegs, da sollte natürlich die Sicherheit nicht auf der Strecke bleiben. Ein Fahrradhelm ist daher immer die richtige Wahl,
                egal oder gemütlich durch den Park oder sportlich im Gelände</p>);

            content.push(<div style={{textAlign: 'left'}}>{subcontent}</div>);
            this.content = content;
        }

        this.api = useFetch();
    }

    componentDidMount() {
        if (this.props.match.params.id == 1) {
            this.getData();
        }
    }

    getData = async () => {
        let rows = []
        let moreData = true;
        let total = 0;
        let timer = false;
        let url = process.env.REACT_APP_API_URL + '/wishlistitems/1' + '/public?include=product,price,alert';
        let {response, data} = await this.api(url);

        console.log(response);
        console.log(data);
        if (!response || response.status == 401) {
            this.props.sessionGotUpdate(true, true);
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            this.props.history.push('/login');
            return;
        }

        if (response && data && data.data) {
            data.data.map((item, index) => {
                //item.edit = false;

                item.attributes.title = data.included.filter((i => i.type == 'products' && i.id == item.attributes.product_id))[0].attributes.title ?? null;
                item.attributes.price = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.price;
                item.attributes.lastUpdated = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.lastUpdated;
                item.attributes.priceId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].id;
                item.attributes.condition = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].attributes.condition : 0;
                item.attributes.alertId = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].id : null;
                item.attributes.productLink = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.productLink;
                item.attributes.imageUrl = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.imageUrl;
                item.attributes.resellerId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.resellerId;


                if (item.attributes.title === '' || item.attributes.price == null) {
                    timer = true;
                }

                if (rows.filter(i => i.id === item.id).length === 0) {
                    rows.push(item);
                } else {
                    let edit = rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit;
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit = edit;
                }
            })


            data.data.map((item, index) => {
                if (rows.filter(i => i.id === item.id).length === 0) {
                    rows[index].delete();
                }
            })

            this.setState({
                items: rows
            }, () => {
                console.log(this.state.items);
            })
        }
    }

    render() {

        console.log(this.props.match.params.id)
        return (
            <Container maxWidth="lg" style={{marginTop: 50}}>
                <div style={{paddingBottom: 100}}>
                    {this.content}

                    {this.props.match.params.id == 1 && <div className="jumbotron" style={{marginTop: 40}}>
                        <h2>Alles gepackt? Bereit zum Abflug? Sicher nichts vergessen?</h2>

                        <p className="lead" style={{paddingBottom: 0}}>Unsere <span
                            style={{color: '#f87800'}}>Highlights</span> die in keinem Urlaub fehlen sollten!</p>
                        <br/>

                        <div className="container" style={{padding: 10}}>
                            <div className="container" key={'container'}>
                                <Grid container spacing={2}
                                      style={{
                                          paddingTop: 10,
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderBottom: '2px solid #ddd'
                                      }}>
                                    <Grid item xs={6} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                        Produkt
                                    </Grid>
                                    <Grid item xs={6} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        Preis*
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {this.state && this.state.items && this.state.items.map((item, index) => {
                            return (
                                <div key={index} className="container priceRow"
                                     style={{borderBottom: index != this.state.items.length - 1 ? '2px solid rgb(221, 221, 221)' : 'none'}}>


                                    <Grid container>
                                        <Grid item xs={12} md={2}>
                                            <a href="" target="_blank"><img style={{paddingBottom: 5}}
                                                                            src={item.attributes.imageUrl}
                                                                            alt=""/></a>
                                        </Grid>

                                        <Grid item xs={12} md={8}>
                                            <div className="productTitleAjax"
                                                 style={{minHeight: 25, padding: 10, textAlign: 'left'}}>
                                                <a style={{lineBreak: 'anywhere'}} href={item.attributes.productLink}
                                                   target={'_blank'}>{item.attributes.title}</a>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={2} style={{padding: 10, textAlign: 'right'}}>
                                            {new Intl.NumberFormat('de-DE', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            }).format((item.attributes.price ?? 0) / 100)}
                                            {item.attributes.lastUpdated &&
                                                <p style={{
                                                    fontSize: 8,
                                                    fontWeight: '400',
                                                    textAlign: 'right'
                                                }}>Aktualisiert: {moment.utc(item.attributes.lastUpdated).local().fromNow()}</p>
                                            }
                                        </Grid>
                                    </Grid>


                                    {/*<div className="row hidden-lg hidden-md hidden-sm"*/}
                                    {/*     style={{fontWeight: 'bold', paddingTop: 10, fontSize: 20}}>*/}
                                    {/*    <div className="col-xs-6 col-sm-6 col-md-6 text-left">Preis*</div>*/}
                                    {/*    <div className="col-xs-6 col-sm-6 col-md-6 text-right">*/}
                                    {/*        {new Intl.NumberFormat('de-DE', {*/}
                                    {/*            style: 'currency',*/}
                                    {/*            currency: 'EUR'*/}
                                    {/*        }).format((item.attributes.price ?? 0) / 100)}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>);
                        })}
                    </div>}


            </div>
    </Container>
    )
        ;
    }
}

export default withRouter(Blog);
