import React from 'react';

import Container from '@material-ui/core/Container';
import {Grid} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import moment from 'moment'
import ReactGA from "react-ga4";

class Home extends React.Component {

    api = undefined

    constructor(props) {
        super(props);
        document.title = 'NICER-PRICER';
        this.api = useFetch();
    }

    componentDidMount() {
        this.getData();
        ReactGA.send('https://nicer-pricer.de' + this.props.location.pathname + this.props.location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + this.props.location.pathname + this.props.location.search);
    }

    getData = async () => {
        let rows = []
        let moreData = true;
        let total = 0;
        let timer = false;
        let url = process.env.REACT_APP_API_URL + '/wishlistitems/1' + '/public?include=product,price,alert&sort=-id';
        let {response, data} = await this.api(url);

        if (response && data && data.data) {
            data.data.map((item, index) => {
                //item.edit = false;

                item.attributes.title = data.included.filter((i => i.type == 'products' && i.id == item.attributes.product_id))[0].attributes.title ?? null;
                item.attributes.price = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.price;
                item.attributes.percentage = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.percentage;
                item.attributes.invalidAsin = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.invalidAsin;
                item.attributes.lastUpdated = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.lastUpdated;
                item.attributes.priceId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].id;
                item.attributes.condition = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].attributes.condition : 0;
                item.attributes.alertId = data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id)).length > 0 ? data.included.filter((i => i.type == 'alerts' && i.attributes.product_id == item.attributes.product_id))[0].id : null;
                item.attributes.productLink = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.productLink;
                item.attributes.imageUrl = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.imageUrl;
                item.attributes.resellerId = data.included.filter((i => i.type == 'prices' && i.attributes.product_id == item.attributes.product_id))[0].attributes.resellerId;


                if (item.attributes.title === '' || item.attributes.price == null) {
                    timer = true;
                }

                if (rows.filter(i => i.id === item.id).length === 0) {
                    rows.push(item);
                } else {
                    let edit = rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit;
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])].edit = edit;
                }
            })


            data.data.map((item, index) => {
                if (rows.filter(i => i.id === item.id).length === 0) {
                    rows[index].delete();
                }
            })

            this.setState({
                items: rows
            }, () => {
                console.log(this.state.items);
            })
        }
    }

    render() {
        let items;

        return (
            <Container maxWidth="lg" style={{marginTop: 50}}>
                <div style={{paddingBottom: 100}}>
                    <div className="jumbotron">
                        <img src={require('./../images/icon.png')} height={120} alt="" style={{paddingBottom: 30}}/>
                        {/*<h1>nicer-<span style={{color: '#f87800'}}>pricer</span></h1>*/}

                        <p className="lead" style={{borderBottom: '1px solid #dfdfdf', paddingBottom: 30}}>Verpasse
                            kein Angebot mehr</p>
                        <p style={{fontSize: 'medium', paddingBottom: 30}}>Amazon-Artikel unterliegen oft
                            Preisschwankungen. Mit Nicer-Pricer kannst Du zum günstigsten Preis kaufen, indem Du die
                            Preise beobachtest. Wenn Dein Wunschpreis erreicht wurde, wirst Du per E-Mail informiert
                            und kannst den Artikel zum besten Preis kaufen. </p>
                    </div>

                    <div className="body-content">

                        <div className="stepcontainer">
                            <ul className="progressbar" style={{padding: 0}}>
                                <li>
                                    {ReactSession.get('token') && <Button
                                        onClick={() => {
                                            this.props.history.push('/wunschlisten/erstellen');
                                        }}
                                        sx={{
                                            color: '#f87800',
                                            background: 'white',
                                            '&:hover': {color: 'white', background: '#f87800',}
                                        }}
                                    >
                                        Wunschlisten erstellen
                                    </Button>}
                                    {!ReactSession.get('token') && <span>Wunschlisten erstellen</span>}
                                </li>
                                <li>
                                    {ReactSession.get('token') && <Button
                                        onClick={() => {
                                            this.props.history.push('/wunschlisten/produkt');
                                        }}
                                        sx={{
                                            color: '#f87800',
                                            background: 'white',
                                            '&:hover': {color: 'white', background: '#f87800',}
                                        }}
                                    >
                                        Produkte anlegen
                                    </Button>}
                                    {!ReactSession.get('token') && <span>Produkte anlegen</span>}

                                </li>
                                <li>
                                    {ReactSession.get('token') && <Button
                                        onClick={() => {
                                            this.props.history.push('/alarme/erstellen');
                                        }}
                                        sx={{
                                            color: '#f87800',
                                            background: 'white',
                                            '&:hover': {color: 'white', background: '#f87800',}
                                        }}
                                    >
                                        Alarm festlegen
                                    </Button>}
                                    {!ReactSession.get('token') && <span>Wunschlisten erstellen</span>}

                                </li>
                            </ul>
                        </div>

                        <Grid container style={{paddingTop: 40, textAlign: 'justify', paddingBottom: 20, fontSize: 14}}
                              id="rowJustify">
                            <Grid item xs={12} md={4} style={{paddingLeft: 15, paddingRight: 15}}>
                                <p>
                                    Erstelle Wunschlisten für jeden Anlass. Weihnachten, Geburtstag oder einfach nur
                                    so.<br/>
                                    Gib Wunschlisten frei und zeige anderen was Dir gefällt, oder lass Deine Listen
                                    privat, nur für dich.
                                </p>
                            </Grid>

                            <Grid item xs={12} md={4} style={{paddingLeft: 15, paddingRight: 15}}>
                                <p>
                                    Füge alle Amazon Produkte, die Dir gefallen, zu Deinen Wunschlisten hinzu.
                                </p>
                            </Grid>

                            <Grid item xs={12} md={4} style={{paddingLeft: 15, paddingRight: 15}}>
                                <p>
                                    Lege Wunschpreise für Deine Produkte fest und werde per E-Mail benachrichtigt sobald
                                    sie diesen Preis unterschreiten.
                                    <br/>
                                    So verpasst Du nie wieder unschlagbare Angebote.
                                </p>
                            </Grid>
                        </Grid>


                        <div className="jumbotron">
                            {/*<h2>Alles gepackt? Bereit zum Abflug? Sicher nichts vergessen?</h2>*/}

                            <p className="lead" style={{paddingBottom: 30}}>Unsere <span
                                style={{color: '#f87800'}}>Highlights</span> solltest Du Dir auf keinen Fall entgehen lassen!</p>
                            <br/>

                            <div className="container" style={{padding: 10}}>
                                    <div className="container" key={'container'}>
                                        <Grid container spacing={2}
                                              style={{
                                                  paddingTop: 10,
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  borderBottom: '2px solid #ddd'
                                              }}>
                                            <Grid item xs={6} style={{textAlign: 'left', fontWeight: 'bold'}}>
                                                Produkt
                                            </Grid>
                                            <Grid item xs={6} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                                Preis*
                                            </Grid>
                                        </Grid>
                                    </div>
                            </div>
                            {this.state && this.state.items && this.state.items.map((item, index) => {
                                return (
                                    <div key={index} className="container priceRow" style={{borderBottom: index != this.state.items.length -1 ? '2px solid rgb(221, 221, 221)' : 'none'}}>



                                        <Grid container direction="row" spacing={2} key={item.id}
                                              style={{
                                                  paddingTop: 10,
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  minHeight: '192px'
                                              }}>
                                            <Grid item xs={12} md={2}>
                                                <div style={{position: 'relative'}}>
                                                    <a href={item.attributes.productLink} target={"_blank"}>
                                                        <img src={item.attributes.imageUrl} style={{maxWidth: 160, maxHeight: 160, width: 'auto', height: 'auto'}} alt=""
                                                             className="imageUrlAjax"/>

                                                    </a>
                                                    {item.attributes.percentage > 0 && <p style={{
                                                        position: 'absolute',
                                                        height: 25,
                                                        width: 25,
                                                        top: 0,
                                                        right: 0,
                                                        fontSize: 12,
                                                        background: '#F78700',
                                                        padding: 10,
                                                        borderRadius: 25,
                                                        lineHeight: '25px',
                                                    }}>-{item.attributes.percentage}%</p>}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={8}>
                                                <div className="productTitleAjax" style={{minHeight: 25, padding: 10, textAlign: 'left'}}>
                                                    <a style={{lineBreak: 'anywhere'}} href={item.attributes.productLink} target={'_blank'}>{item.attributes.title}</a>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={2} style={{padding: 10, textAlign: 'right'}}>
                                                {!item.attributes.invalidAsin && new Intl.NumberFormat('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                }).format((item.attributes.price ?? 0) / 100)}
                                                {!item.attributes.invalidAsin && item.attributes.lastUpdated &&
                                                    <p style={{fontSize: 8, fontWeight: '400', textAlign: 'right'}}>Aktualisiert: {moment.utc(item.attributes.lastUpdated).local().fromNow()}</p>
                                                }
                                                {item.attributes.invalidAsin === 1 && <p>Nicht verfügbar</p>}
                                            </Grid>
                                        </Grid>




                                        {/*<div className="row hidden-lg hidden-md hidden-sm"*/}
                                        {/*     style={{fontWeight: 'bold', paddingTop: 10, fontSize: 20}}>*/}
                                        {/*    <div className="col-xs-6 col-sm-6 col-md-6 text-left">Preis*</div>*/}
                                        {/*    <div className="col-xs-6 col-sm-6 col-md-6 text-right">*/}
                                        {/*        {new Intl.NumberFormat('de-DE', {*/}
                                        {/*            style: 'currency',*/}
                                        {/*            currency: 'EUR'*/}
                                        {/*        }).format((item.attributes.price ?? 0) / 100)}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>);
                            })}
                        </div>


                        <p style={{fontSize: 'xx-small'}}>* Preise können von tatsächlichen Preisen abweichen. Preise
                            enthalten gesetzliche Mehrwertsteuer.</p>


                        <div className="jumbotron" style={{paddingTop: 40}}>
                            <h2>Noch nicht genug?</h2>

                            <p className="lead"> Hier sind weitere spanndende Produkte für
                                <span style={{color: '#f87800'}}> dich</span>!</p>
                            <br/>

                            <table className="table" style={{margin: 'auto'}}>
                                <tbody>
                                <tr>
                                    <td><a href="/blog/1">Alles was in keinem Urlaub
                                        fehlen sollte</a></td>
                                </tr>
                                <tr>
                                    <td><a href="/blog/2">Unsere Technikhighlights</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td><a href="/blog/3">Filme und Bücher für die kalte
                                        Jahreszeit</a></td>
                                </tr>
                                <tr>
                                    <td><a href="/blog/4">Mach aus den Feiertagen ein
                                        Fest</a></td>
                                </tr>
                                <tr>
                                    <td><a href="/blog/5">Alles für ein schönes
                                        Zuhause</a></td>
                                </tr>
                                <tr>
                                    <td><a href="/blog/6">Nutze deine Freizeit</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            </Container>
        );
    }
}

export default withRouter(Home);
