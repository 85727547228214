import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import BaseBreadcrumbs from "./BaseBreadcrumbs";

export default function Faq() {

    const location = useLocation();

    useEffect(() => {
        document.title = 'FAQ';
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
    }, []);

    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>
            <BaseBreadcrumbs routes={[{value: '', label: 'Home'}, {value: 'faq', label: 'FAQ'}]}/>

            <div style={{textAlign: 'left', marginBottom: 100}}>
                <h2>Kostet die Nutzung der App Geld?</h2>
                Nein, Nicer-Pricer ist und bleibt kostenlos.

                <h2>Wann kann ich den Preisverlauf eines Produktes aufrufen?</h2>
                Die Preisverläufe werden grundsätzlich mitgeschrieben und gespeichert, sobald ein Benutzer das Amazon-Produkt der App hinzufügt.
                Das heißt, wenn Du Dir das gleiche Produkt auf die Wunschliste setzt, findest Du hier bereits einen
                gewissen Preisverlauf ab dem Zeitpunkt, zu dem der erste Benutzer das Produkt auf eine Liste gesetzt hat.

                <h2>Ich habe einen Fehler im System gefunden, was kann ich tun?</h2>
                Sende uns gerne eine E-Mail an <a href="mailto:hello@we-push-it.com" style={{whiteSpace: 'nowrap'}}>hello@we-push-it.com</a>.
                Versuche, den Fehler so genau wie möglich zu beschreiben.
                Screenshots können Deine Anfrage unterstützen und helfen uns, das Problem zu erkennen.
                So kann der Fehler schnellstmöglich behoben werden.

                <h2>Ich habe eine super Idee - wohin damit?</h2>
                Cool! Sende uns auch hier eine E-Mail an die <a href="mailto:hello@we-push-it.com" style={{whiteSpace: 'nowrap'}}>hello@we-push-it.com</a>.
                Über Anregungen wie Kritik freuen wir uns sehr.
                Tatsächlich freuen wir uns auch über Hinweise, wenn Dich bei der Nutzung der App etwas massiv stört,
                Du etwas als sehr unpraktikabel empfindest. Als Entwickler sehen wir unsere App durch die rosarote
                Brille -
                Hinweise können uns unterstützen, die App noch nutzerfreundlicher zu gestalten und Schwierigkeiten
                aufzudecken!

                <h2>Wann bekomme ich eine Benachrichtigung von Nicer-Pricer?</h2>
                Sobald der von Dir gesetzte Wunschpreis erreicht wird, bekommst Du eine E-Mail.
            </div>


        </Container>
    );
}
