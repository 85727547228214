import React, {Component, useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import BaseBreadcrumbs from "./BaseBreadcrumbs";
import ApiDataComponent from "./core/ApiDataComponent";
import WunschlistenDatagrid from "./WunschlistenDatagrid";
import Button from "@mui/material/Button";
import base64 from 'base-64'
import useCollapse from 'react-collapsed';
import {Grid, TextField} from "@material-ui/core";
import {useHistory, withRouter} from "react-router-dom";
import {ReactSession} from 'react-client-session';
import {toast} from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Cancel} from "@mui/icons-material";
import useFetch from "./UseFetch";
import SortIcon from '@mui/icons-material/Sort';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ReactGA from "react-ga4";

function Collapsible(props) {

    const {isExpanded, reload, nameInit, isPublicInit, onUpdate, location, match, notify} = props;

    useEffect(() => {
        if (ReactSession.get('token') === undefined) {
            history.push('/login');
        } else if (match.params.type && match.params.type === 'erstellen') {
            window.history.replaceState({}, document.title)
            onUpdate('isExpanded', true);
        }  else if (match.params.type && match.params.type === 'produkt') {
            window.history.replaceState({}, document.title)
            history.push('/wunschlisten');
            notify('Um neue Produkte anzulegen, erstelle bitte zunächst eine neue Wunschliste oder wähle eine vorhandene Wunschliste aus', false, 5000);
        }
    }, [])

    const [name, setName] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const history = useHistory();

    useEffect(() => {
        onUpdate('name', name);
    }, [name])

    useEffect(() => {
        setName(nameInit);
    }, [nameInit])

    useEffect(() => {
        onUpdate('isPublic', isPublic);
    }, [isPublic])

    useEffect(() => {
        setIsPublic(isPublicInit);
    }, [isPublicInit])

    const {getCollapseProps} = useCollapse({isExpanded});

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            props.createWishlist();
        }
    };

    return (
        <div className="collapsible" style={{minHeight: 20}}>
            <div {...getCollapseProps()} style={{display: isExpanded ? 'block' : 'none'}}>
                <Grid container
                      style={{marginTop: 10, minHeight: 20, border: '1px solid rgb(221, 221, 221)', marginBottom: 10}}>
                    <Grid item xs={12} md={6} className="content" style={{padding: 10}}>
                        <TextField variant="outlined"
                                   label={'Name'}
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}
                                   onKeyPress={handleKeypress}
                                   fullWidth
                            /* styles the wrapper */
                                   style={{height: 59.5}}

                            /* styles the label component */
                                   InputLabelProps={{
                                       style: {
                                           height: 59.5,
                                           // ...(!focused && { top: `${labelOffset}px` }),
                                       },
                                   }}

                            /* styles the input component */
                                   inputProps={{
                                       style: {
                                           height: 59.5,
                                           padding: '0 14px',
                                       },
                                   }}/>
                    </Grid>
                    <Grid item xs={12} md={6} className="content" style={{padding: 10}}>
                        <FormControl sx={{width: '100%'}}>
                            <InputLabel>Public</InputLabel>
                            <Select
                                value={isPublic}
                                onChange={e => {
                                    setIsPublic(e.target.value);
                                }}
                                label="Public"
                                fullWidth
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={true}>öffentlich - jeder</MenuItem>
                                <MenuItem value={false}>privat - nur ich</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

class Wunschlisten extends Component {

    api = undefined

    constructor(props) {
        super(props);

        this.reload = this.reload.bind(this);
        this.newData = this.newData.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.api = useFetch();

        this.state = {
            initialDataUrl: props.initialDataUrl + '?page%5Bsize%5D=20&page%5Bnumber%5D=1',
            pageNumber: 1,
            hasMore: false,
            loading: false,
            rows: null,
            isExpanded: false,
            name: '',
            isPublic: false,
            sort: false
        };
        document.title = 'Wunschlisten';
    }

    componentDidMount() {
        this.getData();
        ReactGA.send('https://nicer-pricer.de' + this.props.location.pathname + this.props.location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + this.props.location.pathname + this.props.location.search);
    }

    getData = async (sort = false) => {
        console.log('getData')

        let rows = []
        let moreData = true;
        if (!this.state.newData && !sort) {
            if (this.state.rows !== null) rows = [...this.state.rows];
            else rows = [];
            this.setState({
                newData: false
            })
        }

        for (let i = 1; i < this.state.pageNumber + 1; i++) {

            let url = this.props.initialDataUrl + '?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            if (this.state.sort) url += '&sort=' + this.state.sort;

            let {response, data} = await this.api(url)


            if (!response || response.status == 401) {
                this.props.sessionGotUpdate(true, true);
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                this.props.history.push('/login');
                return;
            }

            if (data && data.data) {
                data.data.map((item, index) => {
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })
                // data.data.map((item, index) => {
                //     if (rows.filter(i => i.id === item.id).length === 0) {
                //         rows[index].delete();
                //     }
                // })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                this.notify('API Fehler: ' + response.status, true);
            }


        }
        this.setState({
            rows: [],
            hasMore: moreData,
            loading: true
        }, () => {
            this.setState({
                rows: rows,
            });
        })
    }

    notify = (message, error, duration = 2000) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: duration,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: duration,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    reload(newData = false, message, error = false) {
        console.log('reload')
        this.setState({
            isExpanded: false,
            newData: newData,
        }, async () => {
            await this.getData(newData);
            if (message) {
                this.notify(message, error);
            }
        })
    }

    setLoading() {
        this.setState({
            loading: false
        })
    }

    newData() {
        if (this.state.hasMore) {
            let temp = ++this.state.pageNumber;
            this.setState({
                pageNumber: temp,
                initialDataUrl: this.props.initialDataUrl
            }, () => {
                this.getData();
            })
        }
    }


    createWishlist = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    name: this.state.name,
                    public: this.state.isPublic
                },
                relationships: {}
            }
        }

        let {
            response,
            data
        } = await this.api(process.env.REACT_APP_API_URL + "/wishlists", 'POST', JSON.stringify(updatedAttributes))

        if (!response || response.status == 401) {
            this.props.sessionGotUpdate(true, true);
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            this.props.history.push('/login');
            return;
        }
        if (response && response.status != '404') {
            this.reload(false, 'Deine Wunschliste wurde erfolgreich angelegt');
            this.setState({name: ''});
            this.props.history.push('/wunschlisten')
        } else if (response.status == '404') {
            this.notify('Wunschliste existiert bereits', true);
        } else {
            this.notify('API Fehler: ' + response.status, true);
        }

    }

    onUpdate = (type, value) => {
        this.setState({
            [type]: value
        })
    }

    render() {
        return (
            <Container maxWidth="lg">
                <BaseBreadcrumbs routes={[{value: '', label: 'Home'}, {value: 'wunschlisten', label: 'Wunschlisten'}]}/>

                <div style={{
                    position: 'relative',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {!this.state.isExpanded &&
                        <Button variant={'outlined'}
                                onClick={() => this.setState({isExpanded: !this.state.isExpanded})}>Wunschliste
                            anlegen</Button>}
                    <span style={{position: 'absolute', right: 0, top: 0}}>{this.state.sort === 'name' &&
                        <ArrowDownwardIcon/>}{this.state.sort === '-name' && <ArrowUpwardIcon/>}<SortIcon
                        onClick={() => {
                            let sort = this.state.sort === false ? 'name' : this.state.sort === 'name' ? '-name' : false;

                            this.setState({
                                    sort
                                }, () =>
                                    this.getData(true)
                            )

                        }}/></span>
                </div>

                {this.state.isExpanded &&
                    <React.Fragment>
                        <Button variant={'contained'} color={'success'}
                                disabled={this.state.name ? this.state.name.length === 0 : true}
                                onClick={() => this.createWishlist()}>Wunschliste anlegen</Button>
                        <Button variant={'contained'} color={'error'} style={{marginLeft: 5}}
                                onClick={() => this.setState({isExpanded: false, name: '', isPublic: false})}><Cancel
                            style={{opacity: 0.8}}/></Button>
                    </React.Fragment>}
                <Collapsible notify={this.notify} createWishlist={this.createWishlist} isExpanded={this.state.isExpanded}
                             nameInit={this.state.name} location={this.props.location} match={this.props.match}
                             isPublicInit={this.state.isPublic} onUpdate={this.onUpdate} reload={this.reload}/>
                <WunschlistenDatagrid notify={this.notify} rows={this.state.rows} newData={this.newData}
                                      hasMore={this.state.hasMore} loading={this.state.loading}
                                      setLoading={this.setLoading} initialDataUrl={this.state.initialDataUrl}
                                      reload={this.reload}/>
            </Container>
        );
    }
}

export default withRouter(Wunschlisten)

