import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {ReactSession} from "react-client-session";
import useFetch from "./components/UseFetch";
import {toast} from "react-toastify";

const pages = [{value: 'wunschlisten', label: 'Wunschlisten'}, {value: 'alarme', label: 'Alarme'}];
const settings = ['Passwort ändern', 'Konto löschen', 'Logout'];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const MenuAppBar = (props) => {
    const {update, sessionGotUpdate} = props;
    const history = useHistory();
    const [location, setLocation] = React.useState(history.location.pathname);


    useEffect(() => {
        if (location !== history.location.pathname) {
            setLocation(history.location.pathname);
        }
    });


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const api = useFetch();


    const onNavigate = async (page) => {

        console.log('onNavigate');

        let url = page.target ? page.target.textContent : page;
        setAnchorElNav(null);
        setAnchorElUser(null);

        if (url == 'Logout') {
            let token = ReactSession.get('token');
            let refreshToken = ReactSession.get('refreshToken');


            let {response, data} = await api(process.env.REACT_APP_API_URL + '/user/logout', 'POST', JSON.stringify({refreshToken}));
            if (response && !response.error) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                sessionGotUpdate(true);
                history.push('/');
            } else {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                sessionGotUpdate();
                history.push('/');
                notify('API Fehler: ' + response.status, true);
            }
        } else if (url == 'Passwort ändern') {
            history.push('/passwort-reset');
        } else if (url == 'Konto löschen') {
            history.push('/konto-loeschen');
        } else {
            history.replace('/' + url);
        }
    };

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    let token = ReactSession.get('token');

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <Button
                            onClick={() => history.push('/')}
                            sx={{ my: 2, color: 'white', display: 'block', '&:hover': { color: '#f87800'}}}
                        >
                            nicer-pricer
                        </Button>
                    </Typography>

                    {token && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={onNavigate}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.value} onClick={() => onNavigate(page.value)}>
                                    <Typography textAlign="center">{page.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <Button
                            onClick={() => history.push('/')}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            nicer-pricer
                        </Button>
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        style={{color: '#f87800'}}
                        sx={{ display: { xs: 'flex', md: 'none', color: '#f87800' } }}
                    >
                        <Button
                            onClick={() => history.push('/faq')}
                            sx={{ my: 2, color: '#f87800', display: 'block' }}
                            style={{color: '#f87800'}}
                        >
                            FAQ
                        </Button>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {ReactSession.get('token') !== undefined && ReactSession.get('newPassword') === undefined &&
                        pages.map((page) => (
                            <Button
                                key={page.value}
                                onClick={() => onNavigate(page.value)}
                                sx={{ my: 2, color: 'white', display: 'block', '&:hover': { color: '#f87800'}}}
                            >
                                {page.label}
                            </Button>
                        ))
                    }
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                        <Button
                            onClick={() => history.push('/faq')}
                            sx={{ my: 2, color: '#f87800', display: 'block' }}
                        >
                            FAQ
                        </Button>
                    </Typography>
                    <Box sx={{ flexGrow: 0 }}>
                    {ReactSession.get('token') !== undefined && ReactSession.get('newPassword') === undefined &&
                        <React.Fragment>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={onNavigate}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        </React.Fragment>
                    }
                    </Box>
                    {ReactSession.get('token') === undefined && <Box sx={{ flexGrow: 0 }}>
                        <Button
                            onClick={(e) => history.push('/login')}
                            sx={{ my: 2, color: 'white', display: 'block', '&:hover': { color: '#f87800'}}}
                        >
                            Login
                        </Button>
                    </Box>}
                    {ReactSession.get('token') === undefined && <Box sx={{ flexGrow: 0 }}>
                        <Button
                            onClick={(e) => history.push('/registration')}
                            sx={{ my: 2, color: 'white', display: 'block', '&:hover': { color: '#f87800'}}}
                        >
                            Registrierung
                        </Button>
                    </Box>}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default MenuAppBar;
