import React, {Component, useEffect} from 'react';

import Container from '@material-ui/core/Container';
import {Image} from "@mui/icons-material";
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import base64 from 'base-64'
import {ReactSession} from 'react-client-session';
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import ReactGA from "react-ga4";

class Verify extends Component {

    api = undefined
    start = false;
    constructor(props) {
        super(props);
        console.log(props);
        this.api = useFetch();

        console.log(props.match.params.token);


    }
    componentDidMount() {
        if (this.props.match.params.token && !this.start) {
            this.start = true;
            this.doVerify(this.props.match.params.token);
        }

        ReactGA.send('https://nicer-pricer.de' + this.props.location.pathname + this.props.location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + this.props.location.pathname + this.props.location.search);

    }


    doVerify = async (token) =>{
        const body = new FormData;

        let updatedAttributes = {
            token
        }

        body.append("data", JSON.stringify(updatedAttributes));
        let headers = new Headers();
        headers.append('Content-Type', 'application/vnd.api+json');

        fetch(process.env.REACT_APP_API_URL + '/user/verify', {
            body: JSON.stringify(updatedAttributes),
            method: "POST",
            headers
        }).then(async response => {
            const data = await response.json();
            this.props.history.push('/login');
            if (response.status != 404 && response.status != 401) {
                this.notify('Dein Account wurde freigeschalten');
            } else if (response.status == 401) {
                this.notify('Dein Account wurde bereits freigeschalten', true);
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    render () {
        return (
            <Container maxWidth="lg" style={{marginTop: 50}}>
                <div>

                </div>
            </Container>
        );
    }
}

export default withRouter(Verify)
