import * as React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {useEffect, useState} from "react";
import {Delete, Edit, SaveAlt, ShoppingCart} from "@mui/icons-material";
import {Grid, Hidden, TextField} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import base64 from "base-64";
import {ReactSession} from "react-client-session";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {confirmAlert} from "react-confirm-alert";
import useFetch from "./UseFetch";

export default function WunschlistenDatagrid(props) {

    const {rows, newData, hasMore, loading, setLoading, initialDataUrl, reload, notify} = props;
    const [hasMoreT, setHasMoreT] = useState(true);
    const api = useFetch();


    const [wishlistName, setWishlistName] = useState(undefined);
    const [isPublic, setIsPublic] = useState(undefined);
    const [wishlistNameError, setWishlistNameError] = useState(undefined);
    const [isPublicError, setIsPublicError] = useState(undefined);

    const [items, setItems] = useState(null);
    const [data, setData] = useState([]);

    const history = useHistory();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
    };

    const handleKeypress = (e,item) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            handleSave(item);
        }
    };

    useEffect(() => {
        setHasMoreT(hasMore);
        if (rows !== null && rows.length !== 0) {
            rows.map((item, index) => {
                item.edit = false;
            })
            setItems(rows);
        }
    }, [])

    useEffect(() => {
        console.log('UPDATE')
        setHasMoreT(hasMore);
        if (rows !== null) {
            const tempItems = [...rows];
            setItems(tempItems);
        }
    }, [rows])

    const confirmDelete = (id) => {
        let credentials = ReactSession.get('credentials');

        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diese Wunschliste wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        let {
                            response,
                            data
                        } = await api(process.env.REACT_APP_API_URL + '/wishlist' + '/' + id, 'DELETE')


                        if (!response || response.status == 401) {
                            return;
                        }

                        if (response) {
                            reload(true, 'Dein Produkt wurde erfolgreich gelöscht');
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    const updateSelect = (e) => {
        let temp = e.target.value;
        setIsPublic(temp);
    }

    const handleOnClick = (item) => {
        console.log('click');
        const tempItems = [...items];

        tempItems.map((i, index) => {
            if (i.id === item.id) {
                i.edit = !i.edit;
                setWishlistName(i.attributes.name);
                setIsPublic(i.attributes.public === 1);
            } else {
                i.edit = false;
            }
        })
        setItems(tempItems)
    }

    const handleSave = async (item) => {
        console.log('click');
        const tempItems = [...items];

        tempItems.map((i, index) => {
            i.edit = false;
        })
        setItems(tempItems);

        const body = new FormData;

        let updatedAttributes = {
            data: {
                attributes: {
                    name: wishlistName,
                    'public': isPublic
                }
            }
        }

        const {response, data} = await api(process.env.REACT_APP_API_URL + "/wishlist/" + item.id, 'PATCH', JSON.stringify(updatedAttributes));

        if (!response || response.status == 401) {
            this.props.sessionGotUpdate(true, true);
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            this.props.history.push('/login');
            return;
        }

        if (response && response.status != '404') {
            reload(false, 'Deine Wunschliste wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {
            reload(false, 'Wunschliste existiert bereits', true);
        } else {
            reload(false, 'API Fehler: ' + response.status, true);
        }
    }


    return (
        <React.Fragment>
            <Hidden xsDown key={'hidden-container'}>
                <div className="container" key={'container'}>
                    <Grid container spacing={2}
                          style={{
                              paddingTop: 10,
                              display: 'flex',
                              alignItems: 'center',
                              borderBottom: '2px solid #ddd'
                          }}>
                        <Grid item xs={12} sm={4} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            Titel
                        </Grid>
                        <Grid item xs={12} sm={4} style={{textAlign: 'left', fontWeight: 'bold'}}>
                            Sichtbarkeit
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
            <InfiniteScroll
                dataLength={items?.length ?? 0} //This is important field to render the next data
                next={newData}
                hasMore={hasMoreT}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        {/*<b>No more items to load</b>*/}
                    </p>
                }
                style={{overflow: 'unset'}}
            >
                {items && items.map((item, index) =>  {
                    return (<Grid container spacing={2} style={{paddingTop: 10, display: 'flex', alignItems: 'center'}}
                                  key={index}>
                        <Grid item xs={12} sm={4} className={'wishlistname'} style={{textAlign: 'left'}}>
                            {!item.edit && <Link
                                style={{fontSize: 16}}
                                component="button"
                                onClick={(e) => {
                                    history.push({
                                        pathname: '/wunschliste/' + item.id,
                                        state: {
                                            data: {
                                                name: item.attributes.name,
                                                public: item.attributes.public,
                                            }
                                        }
                                    })
                                }}>{item.attributes.name}</Link>}
                            {item.edit && <TextField
                                label="Name"
                                variant={'outlined'}
                                onChange={e => {
                                    if (e.target.value.trim().length === 0) {
                                        setWishlistNameError(true);
                                    } else {
                                        setWishlistNameError(false);
                                    }
                                    setWishlistName(e.target.value);
                                }}
                                onKeyPress={(e) => handleKeypress(e,item)}
                                style={{width: '100%'}}
                                value={wishlistName}
                                theme={{
                                    colors: {
                                        primary: '#f87800',
                                        placeholder: '#000000',
                                        text: '#000000',
                                        underlineColor: '#000000',
                                        background: '#FFFFFF',
                                    },
                                }}
                                error={!!wishlistNameError}
                                //helperText={wishlistNameError}
                            />}
                        </Grid>
                        <Grid item xs={12} sm={4} className={'wishlistVisibility'} style={{textAlign: 'left'}}>
                            {!item.edit && (item.attributes.public ? 'öffentlich - jeder' : 'privat - nur ich')}
                            {item.edit && <FormControl sx={{width: '100%'}}>
                                <InputLabel>Public</InputLabel>
                                <Select
                                    value={isPublic}
                                    onChange={(e) => updateSelect(e)}
                                    label="Public"
                                    fullWidth
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={true}>öffentlich - jeder</MenuItem>
                                    <MenuItem value={false}>privat - nur ich</MenuItem>
                                </Select>
                            </FormControl>}

                        </Grid>
                        <Grid item xs={12} sm={4} className={'wishlistEdit'}
                              style={{textAlign: 'right', paddingRight: 0}}>
                            {!item.edit &&
                                <React.Fragment>
                                    <Button variant='outlined' onClick={() => history.push({
                                        pathname: '/wunschliste/' + item.id,
                                        state: {
                                            data: {
                                                name: item.attributes.name,
                                                public: item.attributes.public,
                                            }
                                        }
                                    })} style={{
                                        border: 0,
                                        background: '#337ab7',
                                        fontWeight: '600',
                                        color: 'white',
                                        marginRight: 2
                                    }}>Zur Liste</Button>
                                    <Button variant='outlined' onClick={(e) => {
                                        handleOnClick(item);
                                    }} id={1} style={{
                                        border: 0,
                                        background: '#337ab7',
                                        minWidth: 40,
                                        marginRight: 2,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingTop: 7,
                                        paddingBottom: 7
                                    }}><Edit sx={{fontSize: 20, color: 'white'}}/></Button>
                                    <Button variant='outlined'
                                            onClick={() => confirmDelete(item.id)}
                                            style={{
                                                border: 0,
                                                background: '#d9534f',
                                                minWidth: 40,
                                                marginRight: 2,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingTop: 7,
                                                paddingBottom: 7
                                            }}><Delete sx={{fontSize: 20, color: 'white'}}/></Button></React.Fragment>}
                            {item.edit && <Button variant='outlined' onClick={() => {
                                handleSave(item);
                            }} style={{
                                border: 0,
                                background: '#d9534f',
                                minWidth: 40,
                                marginRight: 2,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 7,
                                paddingBottom: 7
                            }}><SaveAlt sx={{fontSize: 20, color: 'white'}}/></Button>}
                        </Grid>
                    </Grid>)
                })}
            </InfiniteScroll>
        </React.Fragment>
    );
}
