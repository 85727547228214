import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

export default function Privacy() {

    const location = useLocation();

    useEffect(() => {
        document.title = 'Datenschutzerklärung';
        ReactGA.send('https://nicer-pricer.de' + location.pathname + location.search);
console.log(process.env.REACT_APP_FRONTEND_URL + location.pathname + location.search);
    }, []);


    return (
        <Container maxWidth="lg" style={{marginTop: 50}}>

            <div style={{textAlign: 'left'}}>
                <h1>Datenschutzerklärung</h1>

                <div>
                    <br/>

                    <h2 id="m14">Einleitung</h2>
                    <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                        personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken
                        und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                        Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als
                        auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
                        Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet
                        als "Onlineangebot“).</p>
                    <ul className="m-elements"/>
                    <p>Stand: 22. August 2019</p>
                    <h2>Inhaltsübersicht</h2>
                    <ul className="index">
                        <li><a className="index-link" href="#m14"> Einleitung</a></li>
                        <li><a className="index-link" href="#m3"> Verantwortlicher</a></li>
                        <li><a className="index-link" href="#mOverview"> Übersicht der Verarbeitungen</a></li>
                        <li><a className="index-link" href="#m13"> Maßgebliche Rechtsgrundlagen</a></li>
                        <li><a className="index-link" href="#m27"> Sicherheitsmaßnahmen</a></li>
                        <li><a className="index-link" href="#m134"> Einsatz von Cookies</a></li>
                        <li><a className="index-link" href="#m367"> Registrierung und Anmeldung</a></li>
                        <li><a className="index-link" href="#m225"> Bereitstellung des Onlineangebotes und
                            Webhosting</a></li>
                        <li><a className="index-link" href="#m135"> Affiliate-Programme und Affiliate-Links</a></li>
                        <li><a className="index-link" href="#m12"> Löschung von Daten</a></li>
                        <li><a className="index-link" href="#m15"> Änderung und Aktualisierung der
                            Datenschutzerklärung</a></li>
                        <li><a className="index-link" href="#m10"> Rechte der betroffenen Personen</a></li>
                        <li><a className="index-link" href="#m42"> Begriffsdefinitionen</a></li>
                    </ul>

                    <h2 id="m3">Verantwortlicher</h2>
                    <p>PUSH IT GmbH<br/>Neuhauser Weg 5<br/>87439 Kempten</p>
                    <p><strong>E-Mail-Adresse:</strong> <a
                        href="mailto:hello@we-push-it.com">hello@we-push-it.com</a></p>

                    <ul className="m-elements"/>
                    <h2 id="mOverview">Übersicht der Verarbeitungen</h2><p>Die nachfolgende Übersicht fasst die
                    Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf
                    die betroffenen Personen.</p><h3>Arten der verarbeiteten Daten</h3>
                    <ul>
                        <li><p>Bestandsdaten (z.B. Namen, Adressen).</p></li>
                        <li><p>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p></li>
                        <li><p>Kontaktdaten (z.B. E-Mail, Telefonnummern).</p></li>
                        <li><p>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p></li>
                        <li><p>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</p>
                        </li>
                        <li><p>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</p></li>
                    </ul>
                    <h3>Kategorien betroffener Personen</h3>
                    <ul>
                        <li><p>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</p></li>
                    </ul>
                    <h3>Zwecke der Verarbeitung</h3>
                    <ul>
                        <li><p>Affiliate-Nachverfolgung.</p></li>
                        <li><p>Sicherheitsmaßnahmen.</p></li>
                        <li><p>Vertragliche Leistungen und Service.</p></li>
                        <li><p>Verwaltung und Beantwortung von Anfragen.</p></li>
                    </ul>
                    <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3><p>Im Folgenden teilen wir die
                    Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
                    personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den
                    Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und
                    Sitzland gelten können.</p>
                    <ul>
                        <li><p><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene
                            Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen
                            Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.</p></li>
                        <li><p><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
                            DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                            Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher
                            Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.</p></li>
                        <li><p><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
                            Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
                            Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten
                            der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                        </p></li>
                    </ul>
                    <p><strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den
                        Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum
                        Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                        personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
                        enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
                        Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
                        Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
                        Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die
                        Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
                        Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen
                        sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
                        einzelnen Bundesländer zur Anwendung gelangen.</p>
                    <ul className="m-elements"></ul>
                    <h2 id="m27">Sicherheitsmaßnahmen</h2><p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben
                    unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des
                    Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
                    Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
                    natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
                    angemessenes Schutzniveau zu gewährleisten.</p>
                    <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                        Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den
                        Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
                        Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
                        Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung
                        der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
                        bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend
                        dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
                        Voreinstellungen.</p>
                    <p><strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten
                        Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
                        Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.</p>
                    <h2 id="m134">Einsatz von Cookies</h2><p>Als "Cookies“ werden kleine Dateien bezeichnet, die auf
                    Geräten der Nutzer gespeichert werden. Mittels Cookies können unterschiedliche Angaben
                    gespeichert werden. Zu den Angaben können z.B. die Spracheinstellungen auf einer Webseite,
                    der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde,
                    gehören. </p>
                    <p>Cookies werden im Regelfall auch dann eingesetzt, wenn die Interessen eines Nutzers oder sein
                        Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen
                        Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den Nutzern
                        z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren
                        wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer
                        bezeichnet. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die
                        gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer
                        Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet).</p>
                    <p>Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in
                        unserer Datenschutzerklärung. </p>
                    <p><strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre
                        personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um
                        eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies
                        einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                        Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer
                        berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
                        Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies
                        erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.</p>
                    <p><strong>Widerruf und Widerspruch (Opt-Out): </strong> Unabhängig davon, ob die Verarbeitung
                        auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit
                        die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten
                        durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet).</p>
                    <p>Sie können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären,
                        z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die
                        Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann).</p>
                    <p>Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann mittels
                        einer Vielzahl von Diensten, vor allem im Fall des Trackings, über die US-amerikanische
                        Seite <a href="http://www.aboutads.info/choices/"
                                 target="_blank">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                            href="http://www.youronlinechoices.com/"
                            target="_blank">http://www.youronlinechoices.com/</a> oder generell auf <a
                            href="http://optout.aboutads.info"
                            target="_blank">http://optout.aboutads.info</a> erklärt werden.</p>
                    <ul className="m-elements">
                        <li><p><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
                            Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                            Geräte-Informationen, IP-Adressen).</p></li>
                        <li><p><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).</p></li>
                        <li><p><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</p></li>
                    </ul>
                    <h2>Nutzung von Google Analytics</h2>
                    <p>
                        Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc.
                        (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Die Datenverarbeitung
                        dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird Google im Auftrag
                        des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der
                        Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um
                        weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
                        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
                        Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                        Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie
                        ermöglichen. Die durch die Cookies erzeugten Informationen über Ihre Benutzung dieser
                        Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                        gespeichert. Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre
                        IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
                        Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen
                        und dort gekürzt. Ihre Daten werden gegebenenfalls in die USA übermittelt. Für
                        Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission
                        vorhanden. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem
                        berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website.
                        Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
                        dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener
                        Daten zu widersprechen.
                        Sie können dazu die Speicherung der Cookies durch die Auswahl entsprechender technischer
                        Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie
                        in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
                        werden nutzen können. Sie können des Weiteren die Erfassung der durch das Cookie erzeugten
                        und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
                        die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden
                        Link verfügbare Browser-Plug-in herunterladen und installieren
                        [https://tools.google.com/dlpage/gaoptout?hl=de
                        (https://tools.google.com/dlpage/gaoptout?hl=de)]. Um die Erfassung durch Google Analytics
                        geräteübergreifend zu verhindern können Sie einen Opt-Out-Cookie setzen. Opt-Out-Cookies
                        verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Sie müssen das
                        Opt-Out auf allen genutzten Systemen und Geräten durchführen, damit dies umfassend wirkt.
                        Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren.
                        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
                        https://www.google.com/analytics/terms/de.html
                        (https://www.google.com/analytics/terms/de.html) bzw. unter
                        https://www.google.de/intl/de/policies/ (https://www.google.de/intl/de/policies/).
                    </p>
                    <h2 id="m367">Registrierung und Anmeldung</h2><p>Nutzer können ein Nutzerkonto anlegen. Im
                    Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und
                    zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
                    verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die Login-Informationen (Name,
                    Passwort sowie eine E-Mail-Adresse). Die im Rahmen der Registrierung eingegebenen Daten
                    werden für die Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet. </p>
                    <p>Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische
                        Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben,
                        werden deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen
                        Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung
                        vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer
                        gespeicherte Daten des Nutzers unwiederbringlich zu löschen.</p>
                    <p>Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung
                        des Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen
                        Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
                        auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
                        Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur
                        Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht eine gesetzliche
                        Verpflichtung.</p>
                    <ul className="m-elements">
                        <li><p><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
                            Fotografien, Videos), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                            IP-Adressen).</p></li>
                        <li><p><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).</p></li>
                        <li><p><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service,
                            Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.</p></li>
                        <li><p><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</p></li>
                    </ul>
                    <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2><p>Um unser Onlineangebot
                    sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder
                    mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten
                    Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
                    Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
                    Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch
                    nehmen.</p>
                    <p>Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die
                        Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und
                        der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um
                        die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
                        Onlineangebotes oder von Webseiten getätigten Eingaben.</p>
                    <p><strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser
                        Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte
                        Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten
                        und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
                        erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL
                        (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
                    </p>
                    <p>Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um
                        eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen
                        Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
                        Stabilität sicherzustellen.</p>
                    <ul className="m-elements">
                        <li><p><strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Texteingaben,
                            Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                            Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p>
                        </li>
                        <li><p><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).</p></li>
                        <li><p><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
                            DSGVO).</p></li>
                    </ul>
                    <h2 id="m135">Affiliate-Programme und Affiliate-Links</h2><p>In unser Onlineangebot binden wir
                    sogenannte Affiliate-Links oder andere Verweise (zu denen z.B. Rabatt-Codes gehören können)
                    auf die Angebote und Leistungen von Drittanbietern ein (zusammenfassend bezeichnet als
                    "Affiliate-Links"). Wenn Nutzer den Affiliate-Links folgen, bzw. anschließend die Angebote
                    wahrnehmen, können wir von diesen Drittanbietern eine Provision oder sonstige Vorteile
                    erhalten (zusammenfassend bezeichnet als "Provision").</p>
                    <p>Um nachverfolgen zu können, ob die Nutzer die Angebote eines von uns eingesetzten
                        Affiliate-Links wahrgenommen haben, ist es notwendig, dass die jeweiligen Drittanbieter
                        erfahren, dass die Nutzer einem innerhalb unseres Onlineangebotes eingesetzten
                        Affiliate-Link gefolgt sind. Die Zuordnung der Affiliate-Links zu den jeweiligen
                        Geschäftsabschlüssen oder zu sonstigen Aktionen (z.B. Käufen) dient alleine dem Zweck der
                        Provisionsabrechnung und wird aufgehoben, sobald sie für den Zweck nicht mehr erforderlich
                        ist.</p>
                    <p>Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links können die Affiliate-Links um
                        bestimmte Werte ergänzt werden, die ein Bestandteil des Links sind oder anderweitig, z.B. in
                        einem Cookie, gespeichert werden können. Zu den Werten können insbesondere die
                        Ausgangswebseite (Referrer), der Zeitpunkt, eine Online-Kennung der Betreiber der Webseite,
                        auf der sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen Angebotes, die
                        Art des verwendeten Links, die Art des Angebotes und eine Online-Kennung des Nutzers
                        gehören.</p>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
                        den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                        Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen
                        Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen vereinbart wurde.
                        Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h.
                        Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
                        verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
                        Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>
                    <ul className="m-elements">
                        <li><p><strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B. Vertragsgegenstand,
                            Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                            Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                            IP-Adressen).</p></li>
                        <li><p><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).</p></li>
                        <li><p><strong>Zwecke der Verarbeitung:</strong> Affiliate-Nachverfolgung.</p></li>
                        <li><p><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</p></li>
                    </ul>
                    <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                    <ul className="m-elements">
                        <li><p><strong>Amazon-Partnerprogramm:</strong> Amazon - Partnerprogramm - Amazon und das
                            Amazon-Logo sind Warenzeichen von Amazon.com, Inc. oder eines seiner verbundenen
                            Unternehmen. Dienstanbieter: Amazon Europe Core S.à.r.l., die Amazon EU S.à.r.l, die
                            Amazon Services Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig in
                            38, avenue John F. Kennedy, L-1855 Luxemburg, sowie Amazon Instant Video Germany GmbH,
                            Domagkstr. 28, 80807 München (zusammen \"Amazon Europe\"), Mutterunternehmen:
                            Amazon.com, Inc., 2021 Seventh Ave, Seattle, Washington 98121, USA.; Website: <a
                                href="https://www.amazon.de" target="_blank">https://www.amazon.de</a>;
                            Datenschutzerklärung: <a
                                href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
                                target="_blank">https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a>;
                            Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den
                            USA): <a
                                href="https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&status=Active"
                                target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&status=Active</a>.
                        </p></li>
                    </ul>
                    <h2 id="m12">Löschung von Daten</h2><p>Die von uns verarbeiteten Daten werden nach Maßgabe der
                    gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
                    widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung
                    dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).</p>
                    <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                        erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten
                        werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                        handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur
                        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
                        einer anderen natürlichen oder juristischen Person erforderlich ist.</p>
                    <p>Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der
                        einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.</p>
                    <ul className="m-elements"></ul>
                    <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2><p>Wir bitten Sie, sich
                    regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
                    Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
                    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die
                    Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
                    individuelle Benachrichtigung erforderlich wird.</p>
                    <h2 id="m10">Rechte der betroffenen Personen</h2><p>Ihnen stehen als Betroffene nach der DSGVO
                    verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 18 und 21 DS-GVO ergeben:</p>
                    <ul>
                        <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
                            besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                            personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                            Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
                            Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                            Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
                            einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
                            Verbindung steht.</strong></li>
                        <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
                            Einwilligungen jederzeit zu widerrufen.
                        </li>
                        <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu
                            verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
                            sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
                            Vorgaben.
                        </li>
                        <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen
                            Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die
                            Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
                            Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
                            unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
                            eine Einschränkung der Verarbeitung der Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende
                            Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem
                            strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren
                            Übermittlung an einen anderen Verantwortlichen zu fordern.
                        </li>
                        <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der
                            gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in dem
                            Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
                            mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                            betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                        </li>
                    </ul>
                    <h2 id="m42">Begriffsdefinitionen</h2><p>In diesem Abschnitt erhalten Sie eine Übersicht über
                    die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind
                    dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen
                    sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis
                    dienen. Die Begriffe sind alphabetisch sortiert.</p>
                    <ul className="glossary">
                        <li><strong>Affiliate-Nachverfolgung:</strong> Im Rahmen der Affiliate-Nachverfolgung werden
                            Links, mit deren Hilfe die verlinkenden Webseiten Nutzer zu Webseiten mit Produkt- oder
                            sonstigen Angeboten verweisen, protokolliert. Die Betreiber der jeweils verlinkenden
                            Webseiten können eine Provision erhalten, wenn Nutzer diesen sogenannten Affiliate-Links
                            folgen und anschließend die Angebote wahrnehmen (z.B. Waren kaufen oder Dienstleistungen
                            in Anspruch nehmen). Hierzu ist es erforderlich, dass die Anbieter nachverfolgen können,
                            ob Nutzer, die sich für bestimmte Angebote interessieren, diese anschließend auf die
                            Veranlassung der Affiliate-Links wahrnehmen. Daher ist es für die Funktionsfähigkeit von
                            Affiliate-Links erforderlich, dass sie um bestimmte Werte ergänzt werden, die ein
                            Bestandteil des Links werden oder anderweitig, z.B. in einem Cookie, gespeichert werden.
                            Zu den Werten gehören insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt, eine
                            Online-Kennung der Betreiber der Webseite, auf der sich der Affiliate-Link befand, eine
                            Online-Kennung des jeweiligen Angebotes, eine Online-Kennung des Nutzers als auch
                            nachverfolgungsspezifische Werte, wie, z.B. Werbemittel-ID, Partner-ID und
                            Kategorisierungen.
                        </li>
                        <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle
                            Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person
                            (im Folgenden "betroffene Person“) beziehen; als identifizierbar wird eine natürliche
                            Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                            Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                            (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
                            kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                            wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
                        </li>
                        <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder
                            juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
                            mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                            entscheidet, bezeichnet.
                        </li>
                        <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe
                            automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
                            Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
                            jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das
                            Übermitteln oder das Löschen.
                        </li>
                    </ul>
                    <p className="seal"><a href="https://datenschutz-generator.de/?l=de"
                                           title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                           target="_blank">Erstellt mit Datenschutz-Generator.de von Dr. jur. Thomas
                        Schwenke</a></p>
                </div>

            </div>
        </Container>
    );
}
